:root {
  --font-main: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-header: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --color-secondary: #7B16D9;
  --color-secondary-rgb: 123,22,217;
}

body p {
    margin-bottom: 30px;
    font-family: var(--font-main);
    font-size: 17px;
    color: var(--text-color);
    line-height: 1.6em;
}


html,
body {
  height: 100%;
}

body { background-color: black; }

hr {
  max-width: 100px;
  height: 2px;
  border-top: 1px solid white;
  border-bottom: 1px solid white; }

hr {
  border-color: #EF4035; }

@media (min-width: 992px) {
  hr {
    max-width: 150px; } }

section {
  padding: 100px 0;
  color: white;
  background-size: cover;
  position: relative; }

@media (max-width: 767px) {
  section {
    padding: 75px 0; } }

section.light {
  color: var(--color-text);
  background-color: var(--color-light);
}
section.light h2 {
  color:  var(--color-header-primary);
}
section.dark {
  background-color: var(--color-dark);
}

::-webkit-input-placeholder {
  font-weight: 400;
  font-family: var(--font-main); }

:-moz-placeholder {
  font-weight: 400;
  font-family: var(--font-main); }

::-moz-placeholder {
  font-weight: 400;
  font-family: var(--font-main); }

:-ms-input-placeholder {
  font-weight: 400;
  font-family: var(--font-main); }

body {
  font-weight: 400;
  font-family: var(--font-main); }

/*
a {
  color: var(--color-primary);
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s; }
  a:hover, a:focus {
    color: #c81b10;
    text-decoration: none; }

p {
  font-size: 18px;
  line-height: 1.5; }
*/
p.lead {
  font-size: 24px;
  font-family: var(--font-header);
  font-weight: 800;
  text-transform: uppercase; }
/*
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-header);
  font-weight: 700; }

@media (min-width: 768px) {
  h1 {
    font-size: 52px; }
  h2 {
    font-size: 50px; } }
*/

/*===> Begin base style <===*/
body {
  font-family: var(--font-main);
  font-size: 17px;
  color: var(--color-text);
  line-height: 1.6em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  overflow-x: hidden; }
  body :-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-text);
    opacity: 1;
    /* Firefox */ }
  body ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-text);
    opacity: 1;
    /* Firefox */ }
  body :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-text); }
  body ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-text); }
  body * {
    outline: none !important; }
  body h1, body h2, body h3, body h4, body h5, body .h5, body h6 {
    margin-bottom: 15px;
    margin-top: 0;
    font-family: var(--font-header);
    font-weight: bold;
    line-height: 1.3em;
    letter-spacing: -0.03em;
    color: var(--color-header-primary); }
  body h1 {
    font-size: 54px; }
    @media (max-width: 991px) {
      body h1 {
        font-size: 46px; } }
    @media (max-width: 767px) {
      body h1 {
        font-size: 38px; } }
    @media (max-width: 480px) {
      body h1 {
        font-size: 36px; } }
  body h2 {
    font-size: 48px; }
    @media (max-width: 1199px) {
      body h2 {
        font-size: 35px; } }
    @media (max-width: 480px) {
      body h2 {
        font-size: 32px; } }
  body h3 {
    font-size: 36px; }
    @media (max-width: 991px) {
      body h3 {
        font-size: 32px; } }
    @media (max-width: 767px) {
      body h3 {
        font-size: 28px; } }
    @media (max-width: 480px) {
      body h3 {
        font-size: 24px; } }
  body h4 {
    font-size: 24px; }
    @media (max-width: 991px) {
      body h4 {
        font-size: 22px; } }
    @media (max-width: 767px) {
      body h4 {
        font-size: 18px; } }
  body h5, body .h5 {
    font-size: 18px; }
  body h6 {
    font-size: 14px; }
  body p {
    margin-bottom: 30px;
    font-family: var(--font-main);
    font-size: 17px;
    color: var(--color-text);
    line-height: 1.6em; }
    @media (max-width: 1199px) {
      body p {
        font-size: 15px; } }
  body img {
    max-width: 100%;
    display: inline-block; }
  body a {
    display: inline-block;
    text-decoration: none;
    outline: none !important;
    transition: all .3s;
    cursor: pointer; }
    body a::-moz-focus-inner {
      border: 0 !important; }
    body a:hover {
      text-decoration: none;
      color: var(--color-primary); }
  body blockquote {
    padding: 47px 80px 60px 80px;
    background: var(--color-gray); }
    body blockquote.white {
      background: #fff; }
    body blockquote h1, body blockquote h2, body blockquote h3, body blockquote h4, body blockquote h5, body blockquote h6 {
      color: #fff; }
    body blockquote p {
      color: var(--color-text-gray); }
      body blockquote p:last-child {
        margin-bottom: 0; }
    body blockquote .blockquote-head {
      margin-bottom: 20px; }
    body blockquote .blockquote-text {
      position: relative;
      padding: 11px 25px; }
      body blockquote .blockquote-text::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: var(--color-primary);
        /* Old browsers */
        /* FF3.6-15 */
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
        /* IE6-9 fallback on horizontal gradient */ }
    @media (max-width: 991px) {
      body blockquote {
        padding: 40px 60px 50px 60px; } }
    @media (max-width: 767px) {
      body blockquote {
        padding: 30px 40px 30px 40px; } }
    @media (max-width: 480px) {
      body blockquote {
        padding: 15px 20px 15px 20px; } }
  body ul {
    margin-bottom: 0;
    padding: 0; }
    body ul.flex-style {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      margin-bottom: -15px; }
      body ul.flex-style li {
        width: 50%; }
    body ul.no-order li {
      padding-left: 0; }
      body ul.no-order li::after {
        display: none; }
    body ul li {
      position: relative;
      margin-bottom: 15px;
      list-style: none; }
      body ul li:last-child {
        margin-bottom: 0; }
      body ul.slick-dots li::after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 900;
        background: var(--color-primary); }
      body ul.slick-dots li p {
        margin-bottom: 0; }
      body ul.slick-dots li a {
        margin-bottom: 0;
        font-family: var(--font-main);
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 1.6em;
        color: var(--color-text); }
  body ol {
    margin-bottom: 0;
    padding: 0;
    counter-reset: myCounter; }
    body ol li {
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 15px;
      list-style-position: inside;
      list-style: none; }
      body ol li::before {
        content: counter(myCounter) ".";
        counter-increment: myCounter;
        display: inline-block;
        margin-right: 10px;
        font-family: var(--font-header);
        font-size: 17px;
        font-weight: bold;
        color: var(--color-primary); }
      body ol li p {
        margin-bottom: 0; }
  body .font-family-main {
    font-family: var(--font-main) !important; }
  body .font-family-second {
    font-family: var(--font-header) !important; }
  body .color-primary {
    color: var(--color-primary) !important; }
  body .color-secondary {
    color: var(--color-secondary) !important; }
  body .color-text {
    color: var(--color-text) !important; }
  body .color-text-gray {
    color: var(--color-text-gray) !important; }
  body .color-header-primary {
    color: var(--color-header-primary) !important; }
  body .fw-100 {
    font-weight: 100 !important; }
  body .fw-200 {
    font-weight: 200 !important; }
  body .fw-300 {
    font-weight: 300 !important; }
  body .fw-400 {
    font-weight: 400 !important; }
  body .fw-500 {
    font-weight: 500 !important; }
  body .fw-600 {
    font-weight: 600 !important; }
  body .fw-700 {
    font-weight: 700 !important; }
  body .fw-800 {
    font-weight: 800 !important; }
  body .fw-900 {
    font-weight: 900 !important; }
  body .theme-color {
    color: var(--color-primary) !important; }
  body .font-gradient {
    background: -webkit-linear-gradient(45deg, var(--color-secondary) 30%, var(--color-primary) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--color-primary); }
  body .p--sm {
    font-size: 13px; }
  body section.light {
    background-color: #fff; }
  body .white-color {
    color: #fff !important; }
  body section.dark {
    background-color: var(--color-dark); }
  body .ovh {
    overflow: hidden; }
  body .section-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-size: cover; }
    body .section-bg.op-1 {
      opacity: .1; }
    body .section-bg.op-2 {
      opacity: .2; }
    body .section-bg.op-3 {
      opacity: .3; }
    body .section-bg.op-4 {
      opacity: .4; }
    body .section-bg.op-5 {
      opacity: .5; }
    body .section-bg.op-6 {
      opacity: .6; }
    body .section-bg.op-7 {
      opacity: .7; }
    body .section-bg.op-8 {
      opacity: .8; }
    body .section-bg.op-9 {
      opacity: .9; }
    @media (min-width: 992px) {
      body .section-bg.bg-fixed {
        background-attachment: fixed; } }
  body .container {
    position: relative;
    z-index: 10; }
  @media (max-width: 767px) {
    body .collapse-clients.collapsed-xs {
      display: none; } }
  @media (max-width: 991px) {
    body .collapse-clients.collapsed-sm {
      display: none; }
    body .collapse-clients.collapsed-xs.collapsed-sm {
      display: block; } }
  @media (min-width: 576px) {
    body .container {
      max-width: 738px; } }
  @media (min-width: 768px) {
    body .container {
      max-width: 776px; } }
  @media (min-width: 992px) {
    body .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    body .container {
      max-width: 1200px; } }

/*===> End base style <===*/


.intro h1 {
    background: -webkit-linear-gradient(45deg, var(--color-secondary) 30%, var(--color-primary) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--color-primary);
    display: inline-block;
}

.intro .intro-body h2,
.intro .intro-body h3 {
    text-transform: none;
    margin: .2rem 0 2rem;
    color:  #ffffff;
}

.container-fluid > .block-two_column > .row > div {
  display: table;
}
.container-fluid > .block-two_column > .row > div > .block {
  display: table-cell;
  vertical-align: middle;
}

.logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .logo img {
    margin-right: 10px;
    height:  50px; }
  .logo .logo-text {
    font-family: var(--font-header); }
    .logo .logo-text span {
      display: block;
      color: #fff; }
      .logo .logo-text span:first-child {
        font-size: 42px;
        font-weight: 800;
        line-height: 42px;
        letter-spacing: -0.03em; }
      .logo .logo-text span:last-child {
        padding-left: 5px;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.25em;
        line-height: 15px; }

.header-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  padding: 0 60px;
  background: var(--color-dark); }
  .header-wrap.is-scroll {
    position: fixed; }
  .header-wrap header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding: 15px 0; }
    .header-wrap header .header-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 1440px) {
    .header-wrap {
      padding: 0 15px; } }
  @media (max-width: 991px) {
    .header-wrap {
      height: 64px; }
      .header-wrap.is-scroll {
        height: 64px;
        padding: 0 15px; }
      .header-wrap .logo .logo-text span:first-child {
        font-size: 28px;
        line-height: 1em; }
      .header-wrap .logo .logo-text span:last-child {
        padding-top: 2px;
        padding-left: 2px;
        font-size: 7px;
        line-height: 1em; }
      .header-wrap .logo img {
        height: 30px; } }

.hamburger {
  position: relative;
  display: none;
  width: 18px;
  min-width: 18px;
  height: 16px;
  -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }
  .hamburger.open span:nth-child(1) {
    top: 7px;
    -ms-transform: rotate(135deg);
        transform: rotate(135deg); }
  .hamburger.open span:nth-child(2) {
    opacity: 0;
    right: 60px; }
  .hamburger.open span:nth-child(3) {
    top: 7px;
    width: 100%;
    -ms-transform: rotate(-135deg);
        transform: rotate(-135deg); }
  .hamburger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 2px;
    opacity: 1;
    right: 0;
    -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    transition: .25s ease-in-out; }
    .hamburger span:nth-child(1) {
      top: 0px; }
    .hamburger span:nth-child(2) {
      top: 7px; }
    .hamburger span:nth-child(3) {
      top: 14px; }
  @media (max-width: 1199px) {
    .hamburger {
      display: block; } }

.main-nav {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }
  .main-nav .navigation {
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: unset;
    -ms-flex-direction: unset; }
    .main-nav .navigation > li {
      position: relative;
      list-style: none;
      margin-right: 45px; }
    .main-nav .navigation li {
      margin-bottom: 0;
      padding-left: 0; }
      .main-nav .navigation li::after {
        display: none; }
      .main-nav .navigation li::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0%;
        height: 4px;
        border-radius: 4px;
        transition: all .3s;
        background: var(--color-primary);
        /* Old browsers */
        /* FF3.6-15 */
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(-45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
        /* IE6-9 fallback on horizontal gradient */ }
      .main-nav .navigation li:last-child {
        margin-right: 0; }
      .main-nav .navigation li:hover > .sub-menu {
        opacity: 1;
        visibility: visible;
        top: 100%;
        -ms-transform: translateY(-4px);
            transform: translateY(-4px); }
      .main-nav .navigation li:nth-child(n+4) .sub-menu {
        left: auto;
        right: 0%; }
        .main-nav .navigation li:nth-child(n+4) .sub-menu .sub-menu {
          left: auto;
          right: 100%; }
      .main-nav .navigation li.active > a {
        text-transform: uppercase;
        color: var(--color-primary); }
      .main-nav .navigation li a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: justify;
            justify-content: space-between;
        padding: 18px 0;
        font-family: var(--font-header);
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase; }
        .main-nav .navigation li a svg {
          display: none;
          width: 10px; }
          .main-nav .navigation li a svg path {
            fill: #fff; }
  @media (max-width: 1400px) {
    .main-nav .navigation > li {
      margin-right: 30px; } }
  .main-nav .sub-menu {
    position: absolute;
    z-index: 10;
    top: 110%;
    left: 0;
    min-width: 185px;
    border-radius: 12px;
    background: #fff;
    transition: all .3s;
    opacity: 0;
    visibility: hidden; }
    .main-nav .sub-menu::before {
      content: '';
      position: absolute;
      z-index: -10;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: var(--color-primary);
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .main-nav .sub-menu::after {
      content: '';
      position: absolute;
      z-index: -8;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: #fff; }
    .main-nav .sub-menu li {
      display: block;
      width: 100%;
      z-index: 10;
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      padding-left: 0; }
      .main-nav .sub-menu li:first-child {
        padding-top: 10px;
        border-radius: 12px 12px 0 0; }
      .main-nav .sub-menu li:last-child {
        padding-bottom: 10px;
        border-radius: 0 0 12px 12px; }
      .main-nav .sub-menu li:hover > .sub-menu {
        opacity: 1;
        visibility: visible;
        top: 0%;
        -ms-transform: translateY(0);
            transform: translateY(0); }
      .main-nav .sub-menu li a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: justify;
            justify-content: space-between;
        padding: 8px 22px;
        text-transform: uppercase;
        color: var(--color-header-primary);
        /* svg {
          width: 10px;
          path {
            fill: var(--color-header-primary);
          }
        } */ }
        .main-nav .sub-menu li a:hover {
          color: var(--color-primary); }
    .main-nav .sub-menu .sub-menu {
      left: 100%;
      top: 30px; }
  @media (max-width: 1199px) {
    .main-nav {
      position: fixed;
      top: 63px;
      right: 0;
      width: 100%;
      height: calc(100vh - 63px);
      -ms-flex-pack: start;
          justify-content: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
      background: rgba(var(--color-dark-rgb), 0.5);
      opacity: 0;
      transition: all .3s;
      visibility: hidden; }
      .main-nav.show-menu {
        visibility: visible;
        opacity: 1; }
        .main-nav.show-menu .navigation {
          -ms-transform: translateX(0);
              transform: translateX(0); }
      .main-nav .navigation {
        width: 100%;
        max-width: 320px;
        margin-left: auto;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
        height: 100%;
        overflow: auto;
        padding: 20px;
        background: rgba(var(--color-dark-rgb), 1);
        -ms-transform: translateX(100%);
            transform: translateX(100%);
        transition: transform .3s; }
        .main-nav .navigation li {
          display: block;
          width: 100%;
          margin-right: 0; }
          .main-nav .navigation li a.active svg {
            -ms-transform: rotate(180deg);
                transform: rotate(180deg); }
          .main-nav .navigation li a svg {
            display: block;
            transition: all .3s; }
      .main-nav .sub-menu {
        position: static;
        display: none;
        visibility: visible;
        opacity: 1;
        transition: all 0s;
        background: none; }
        .main-nav .sub-menu::before, .main-nav .sub-menu::after {
          display: none; }
        .main-nav .sub-menu li a {
          padding-right: 0;
          color: #fff; }
        .main-nav .sub-menu li ul {
          padding-left: 15px; }
          .main-nav .sub-menu li ul li:first-child {
            padding-top: 0; }
      .main-nav .menu-gradient {
        display: none; } }

.page-slug-home .header-wrap {
  background-color: transparent;
  transition: background-color .3s; }
  .page-slug-home .header-wrap.is-scroll {
    background-color: var(--color-dark); }

.page-slug-home .clone-nav {
  display: none !important; }

.menu-gradient {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  height: 4px;
  border-radius: 4px;
  transition: all .3s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.285, 1.3);
  background: var(--color-primary);
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(-45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }


footer {
  background-size: cover;
  background-position: center;
  background-color: var(--color-dark);
  z-index: 1;
  position: relative; }
  footer .footer-bottom {
    color: var(--color-text-gray); }
    footer .footer-bottom p {
      color: var(--color-text-gray); }
    footer .footer-bottom a {
      font-weight: normal;
      color: var(--color-primary); }
      footer .footer-bottom a:hover {
        color: #fff; }
  @media (max-width: 991px) {
    footer .footer-nav {
      display: none; } }
  @media (max-width: 480px) {
    footer .logo img {
      width: 50px; }
    footer .logo .logo-text span:first-child {
      font-size: 26px;
      line-height: 26px; }
    footer .logo .logo-text span:last-child {
      font-size: 6px; } }

.footer-nav {
  max-width: 1030px;
  margin-left: auto;
  margin-right: auto; }
  .footer-nav ul {
    flex-direction:  unset;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .footer-nav ul li {
      margin-bottom: 0;
      padding-left: 0; }
      .footer-nav ul li::after {
        display: none; }
      .footer-nav ul li a {
        font-family: var(--font-header);
        font-size: 18px;
        font-weight: bold;
        text-transform: capitalize;
        color: #fff; }
        .footer-nav ul li a:hover {
          color: var(--color-primary); }

.flex {
  display: -ms-flexbox;
  display: flex; }

.contact-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto; }
  .contact-list li {
    padding-left: 0;
    width: 33.33%; }
    .contact-list li::after {
      display: none; }
  .contact-list .contact-icon {
    margin-right: 15px; }
    .contact-list .contact-icon svg path {
      fill: var(--color-primary); }
    .contact-list .contact-icon::before {
      color: var(--color-primary);
      vertical-align: middle; }
  .contact-list .contact-list_text h5 {
    color: #fff; }
  .contact-list .contact-list_text a, .contact-list .contact-list_text p {
    display: block;
    margin-bottom: 0;
    color: var(--color-text-gray); }
  .contact-list .contact-list_text a:hover {
    color: var(--color-primary); }
  @media (max-width: 991px) {
    .contact-list .contact-list_text h5 {
      font-size: 16px; }
    .contact-list .contact-list_text a, .contact-list .contact-list_text p {
      font-size: 15px; } }
  @media (max-width: 767px) {
    .contact-list {
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center; }
      .contact-list li {
        width: 240px; } }

.social-media-links {
  display: -ms-flexbox;
  display: flex; }
  .social-media-links.center {
    -ms-flex-pack: center;
        justify-content: center; }
  .social-media-links.dark li a:hover svg path {
    fill: var(--color-primary); }
  .social-media-links.dark li a:hover .fab {
    color: var(--color-primary); }
  .social-media-links.dark li a svg path {
    transition: all .3s;
    fill: var(--color-header-primary); }
  .social-media-links.dark li a .fab {
    transition: all .3s;
    color: var(--color-header-primary); }
  .social-media-links.small li {
    margin-right: 25px; }
  .social-media-links.small svg {
    height: 13px; }
  .social-media-links li {
    margin-bottom: 0;
    margin-right: 40px;
    padding-left: 0; }
    .social-media-links li:last-child {
      margin-right: 0; }
    .social-media-links li::after {
      display: none; }
    .social-media-links li a:hover svg path {
      fill: var(--color-primary); }
    .social-media-links li a:hover .fab {
      color: var(--color-primary); }
    .social-media-links li a svg path {
      transition: all .3s;
      fill: #fff; }
    .social-media-links li a .fab {
      transition: all .3s;
      color: #fff; }


.btn {
  font-weight: 700;
  padding: 12px 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0; }

.btn-primary {
  position: relative;
  min-width: 186px;
  padding: 20px 15px;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  font-family: var(--font-header);
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  color: #fff;
  background-size: 100% 100%;
  transition: background .3s ease-out, color .3s;
  background: var(--color-primary);
  background: linear-gradient(-45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
}

.btn-secondary {
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);
  background-color: transparent; }
  .btn-secondary:hover,
  .btn-secondary:focus,
  .btn-secondary.mixitup-control-active {
    color: white;
    border-color: #EF4035;
    background-color: #EF4035; }

button:hover {
  cursor: pointer; }

.btn-full-width {
  font-size: 26px;
  font-weight: 200;
  padding: 50px 0;
  letter-spacing: normal;
  text-transform: none;
  color: white;
  border: 0;
  border-radius: 0;
  background-color: #222; }
  .btn-full-width:hover {
    color: white;
    background-color: #EF4035; }
  .btn-full-width:focus {
    color: white; }

.text-primary {
  color: #EF4035 !important; }

a.text-primary {
  color: #EF4035 !important; }
  a.text-primary:hover, a.text-primary:focus {
    color: #c81b10 !important; }

.bg-inverse {
  background-color: #222 !important; }


.btn-default {
    border: 1px solid #EF4035;
    color: #EF4035;
    background-color: transparent;
    font-weight: 400;
    letter-spacing: 0px;
}

.btn-default:hover,
.btn-default:focus {
    border: 1px solid #EF4035;
    outline: 0;
    color: #000;
    background-color: #EF4035;
}
.btn-xs {
  padding: .18rem .35rem;
  font-size: .8rem;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}


/*===> Begin button <===*/
.btn,
.button {
  position: relative;
  min-width: 186px;
  padding: 20px 15px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-family: var(--font-header);
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  color: #fff;
  background-size: 100% 100%;
  transition: background .3s ease-out, color .3s;
  background: var(--color-primary);
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(-45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }
  .btn::after,
  .button::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 30px;
    background: var(--color-primary);
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(-45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
  .btn:hover,
  .button:hover {
    background-size: 150% 100%;
    color: var(--color-header-primary);
    cursor: pointer !important; }
    .btn:hover svg path,
    .button:hover svg path {
      fill: var(--color-primary); }
  .btn.btn-shadow,
  .button.btn-shadow {
    box-shadow: 0px 9px 20px #626065; }
  .btn.sm-text,
  .button.sm-text {
    font-size: 13px; }
  .btn.center-btn,
  .button.center-btn {
    display: block;
    width: 186px;
    margin-left: auto;
    margin-right: auto; }
  .btn.white,
  .button.white {
    padding: 22px 17px;
    background: #fff;
    overflow: hidden;
    color: var(--color-header-primary); }
    .btn.white.btn-border,
    .button.white.btn-border {
      overflow: visible;
      padding: 20px 15px; }
      .btn.white.btn-border::before,
      .button.white.btn-border::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 30px;
        transition: all .3s; }
      .btn.white.btn-border::after,
      .button.white.btn-border::after {
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        opacity: 1;
        z-index: -2;
        background: var(--color-primary);
        /* Old browsers */
        /* FF3.6-15 */
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(-45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
        /* IE6-9 fallback on horizontal gradient */ }
      .btn.white.btn-border:hover::before,
      .button.white.btn-border:hover::before {
        opacity: 0; }
    .btn.white:hover,
    .button.white:hover {
      background-color: transparent;
      color: #fff; }
      .btn.white:hover::after,
      .button.white:hover::after {
        opacity: 1; }
      .btn.white:hover svg path,
      .button.white:hover svg path {
        fill: #fff; }
    .btn.white::after,
    .button.white::after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: all .3s; }
    .btn.white svg,
    .button.white svg {
      margin-right: 5px; }
      .btn.white svg path,
      .button.white svg path {
        fill: var(--color-primary);
        transition: all .3s; }
  .btn.dark,
  .button.dark {
    background: var(--color-dark);
    color: #fff; }
    .btn.dark:hover,
    .button.dark:hover {
      background: #fff;
      color: var(--color-header-primary); }
    .btn.dark svg path,
    .button.dark svg path {
      fill: var(--color-primary); }
  .btn svg,
  .button svg {
    margin-right: 5px; }
    .btn svg path,
    .button svg path {
      fill: #fff;
      transition: all .3s; }
  @media (max-width: 991px) {
    .btn,
    .button {
      font-size: 13px; } }

/*===> End button <===*/
/*===> Begin keyframes <===*/
@keyframes parallax-img {
  from {
    margin-top: 0; }
  50% {
    margin-top: 50px; }
  to {
    margin-top: 0; } }

@keyframes pulse {
  from {
    width: 100%;
    height: 100%;
    opacity: .8; }
  50% {
    width: 140%;
    height: 140%;
    opacity: 0; }
  to {
    width: 100%;
    height: 100%;
    opacity: 0; } }

@keyframes cd-rotate-2-in {
  0% {
    opacity: 0;
    transform: translateZ(-20px) rotateX(90deg); }
  60% {
    opacity: 1;
    transform: translateZ(-20px) rotateX(-10deg); }
  100% {
    opacity: 1;
    transform: translateZ(-20px) rotateX(0deg); } }

@keyframes cd-rotate-2-out {
  0% {
    opacity: 1;
    transform: translateZ(-20px) rotateX(0); }
  60% {
    opacity: 0;
    transform: translateZ(-20px) rotateX(-100deg); }
  100% {
    opacity: 0;
    transform: translateZ(-20px) rotateX(-90deg); } }

/*===> End keyframes <===*/
/*===> Begin slick-arrow <===*/
.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 10;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer !important; }
  .slick-arrow:hover svg path {
    fill: var(--color-primary);
    stroke: var(--color-primary);}
  .slick-arrow.slick-prev {
    left: 50px; }
  .slick-arrow.slick-next {
    right: 50px; }
  .slick-arrow svg path {
    fill: var(--color-slick-arrow);
    transition: all .3s;
    stroke-width: 2;
    stroke: var(--color-slick-arrow); }

.arrow-outside .slick-arrow.slick-prev {
  left: 0px;
  -ms-transform: translateX(-100px);
      transform: translateX(-100px); }

.arrow-outside .slick-arrow.slick-next {
  right: 0px;
  -ms-transform: translateX(100px);
      transform: translateX(100px); }

@media (max-width: 1440px) {
  .arrow-outside .slick-arrow.slick-prev {
    -ms-transform: translateX(-50%);
        transform: translateX(-50%); }
  .arrow-outside .slick-arrow.slick-next {
    -ms-transform: translateX(50%);
        transform: translateX(50%); } }

@media (max-width: 1199px) {
  .arrow-outside .slick-arrow.slick-prev {
    -ms-transform: translateX(0);
        transform: translateX(0); }
  .arrow-outside .slick-arrow.slick-next {
    -ms-transform: translateX(0);
        transform: translateX(0); } }

.white-arrow .slick-arrow:hover svg path {
  fill: var(--color-primary); }

.white-arrow .slick-arrow svg path {
  fill: #fff; }

/*===> End slick-arrow <===*/
/*===> Begin slick-dots <===*/
.slick-dots {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 40px;
  padding: 0 15px; }
  .slick-dots li {
    padding-left: 0;
    margin-bottom: 0;
    margin-right: 20px; }
    .slick-dots li::after {
      display: none; }
    .slick-dots li.slick-active button {
      background: var(--color-primary); }
    .slick-dots li button {
      display: block;
      width: 10px;
      height: 10px;
      padding: 0;
      border: none;
      border-radius: 50%;
      background: var(--color-slick-dots-bg);
      font-size: 0;
      line-height: 0;
      transition: all .3s; }
  @media (max-width: 767px) {
    .slick-dots {
      margin-top: 30px; } }

.inner-dots .slick-dots {
  position: absolute;
  bottom: 40px; }

/*===> End slick-dots <===*/


/*Begin fonts*/
@font-face {
  font-family: 'recording';
  src: url("fonts/recording.eot?tc8bkk");
  src: url("fonts/recording.eot?tc8bkk#iefix") format("embedded-opentype"), url("fonts/recording.ttf?tc8bkk") format("truetype"), url("fonts/recording.woff?tc8bkk") format("woff"), url("fonts/recording.svg?tc8bkk#recording") format("svg");
  font-weight: normal;
  font-style: normal; }

.rec-icon {
  font-family: 'recording' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.icon-bag:before {
  content: "\e93b"; }

.icon-promotion-1:before {
  content: "\e900"; }

.icon-audio:before {
  content: "\e903"; }

.icon-settings:before {
  content: "\e904"; }

.icon-mic:before {
  content: "\e905"; }

.icon-promotion:before {
  content: "\e906"; }

.icon-interface:before {
  content: "\e907"; }

.icon-people:before {
  content: "\e913"; }

.icon-travel:before {
  content: "\e914"; }

.icon-quaver:before {
  content: "\e916"; }

.icon-folded-newspaper:before {
  content: "\e917"; }

.icon-picture:before {
  content: "\e919"; }

.icon-quotation:before {
  content: "\e91b"; }

.icon-telephone:before {
  content: "\e91c"; }

.icon-clock:before {
  content: "\e91d"; }

.icon-music-headphones:before {
  content: "\e91e"; }

.icon-music-cd:before {
  content: "\e91f"; }

.icon-music-cd-1:before {
  content: "\e920"; }

.icon-music-equalizer:before {
  content: "\e924"; }

.icon-mic-2:before {
  content: "\e925"; }

.icon-music-equalizer-1:before {
  content: "\e926"; }

.icon-play:before {
  content: "\e928"; }

.icon-sound:before {
  content: "\e929"; }

.icon-social:before {
  content: "\e92a"; }

.icon-note:before {
  content: "\e92b"; }

.icon-square:before {
  content: "\e92c"; }

.icon-mic-3:before {
  content: "\e92d"; }

.icon-big-speech-balloon:before {
  content: "\e92e"; }

.icon-sky:before {
  content: "\e92f"; }

.icon-placeholder-for-map:before {
  content: "\e901"; }

.icon-facebook-logo:before {
  content: "\e908"; }

.icon-twitter-logo:before {
  content: "\e909"; }

.icon-googleplus-logo:before {
  content: "\e90a"; }

.icon-linkedin-logo:before {
  content: "\e90b"; }

.icon-star:before {
  content: "\e90c"; }

.icon-search:before {
  content: "\e90d"; }

.icon-plus:before {
  content: "\e90e"; }

.icon-remove:before {
  content: "\e90f"; }

.icon-three:before {
  content: "\e910"; }

.icon-arrow-top:before {
  content: "\e921"; }

.icon-arrow-left:before {
  content: "\e937"; }

.icon-arrow-right:before {
  content: "\e936"; }

.icon-arrow-down:before {
  content: "\e911"; }

.icon-left-arrow:before {
  content: "\e912"; }

.icon-right-arrow:before {
  content: "\e915"; }

.icon-user:before {
  content: "\e91a"; }

.icon-play-circle:before {
  content: "\e927"; }

.icon-pause-circle:before {
  content: "\e902"; }

.icon-next-track:before {
  content: "\e918"; }

.icon-prev-track:before {
  content: "\e923"; }

.icon-like:before {
  content: "\e922"; }

.icon-sound-on:before {
  content: "\e930"; }

.icon-bulb:before {
  content: "\e931"; }

.icon-mail:before {
  content: "\e932"; }

.icon-facebook-logo-lined:before {
  content: "\e933"; }

.icon-twitter-logo-lined:before {
  content: "\e934"; }

.icon-instagram-logo-lined:before {
  content: "\e935"; }

.icon-download:before {
  content: "\e939"; }

.icon-calendar:before {
  content: "\e938"; }

.icon-share:before {
  content: "\e93a"; }

/*End fonts*/
/*Begin padding/margin*/
h3 + h1 {
  margin-top: 10px; }

.letter-spacing-0 {
  letter-spacing: 0; }

.pt-0 {
  padding-top: 0 !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-8 {
  padding-top: 8px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-18 {
  padding-top: 18px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-31 {
  padding-top: 31px !important; }

.pt-32 {
  padding-top: 32px !important; }

.pt-33 {
  padding-top: 33px !important; }

.pt-34 {
  padding-top: 34px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-36 {
  padding-top: 36px !important; }

.pt-37 {
  padding-top: 37px !important; }

.pt-38 {
  padding-top: 38px !important; }

.pt-39 {
  padding-top: 39px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-65 {
  padding-top: 65px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-95 {
  padding-top: 95px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-110 {
  padding-top: 110px !important; }

.pt-120 {
  padding-top: 120px !important; }

.pt-130 {
  padding-top: 130px !important; }

.pt-140 {
  padding-top: 140px !important; }

.pt-150 {
  padding-top: 150px !important; }

.pt-160 {
  padding-top: 160px !important; }

.pt-170 {
  padding-top: 170px !important; }

.pt-175 {
  padding-top: 175px !important; }

.pt-180 {
  padding-top: 180px !important; }

.pt-190 {
  padding-top: 190px !important; }

.pt-200 {
  padding-top: 200px !important; }

.pt-210 {
  padding-top: 210px !important; }

.pt-220 {
  padding-top: 220px !important; }

.pt-240 {
  padding-top: 240px !important; }

.pt-280 {
  padding-top: 280px !important; }

@media (min-width: 1px) {
  .pt-xs-0 {
    padding-top: 0 !important; }
  .pt-xs-5 {
    padding-top: 5px !important; }
  .pt-xs-8 {
    padding-top: 8px !important; }
  .pt-xs-10 {
    padding-top: 10px !important; }
  .pt-xs-15 {
    padding-top: 15px !important; }
  .pt-xs-18 {
    padding-top: 18px !important; }
  .pt-xs-20 {
    padding-top: 20px !important; }
  .pt-xs-25 {
    padding-top: 25px !important; }
  .pt-xs-30 {
    padding-top: 30px !important; }
  .pt-xs-31 {
    padding-top: 31px !important; }
  .pt-xs-32 {
    padding-top: 32px !important; }
  .pt-xs-33 {
    padding-top: 33px !important; }
  .pt-xs-34 {
    padding-top: 34px !important; }
  .pt-xs-35 {
    padding-top: 35px !important; }
  .pt-xs-36 {
    padding-top: 36px !important; }
  .pt-xs-37 {
    padding-top: 37px !important; }
  .pt-xs-38 {
    padding-top: 38px !important; }
  .pt-xs-39 {
    padding-top: 39px !important; }
  .pt-xs-40 {
    padding-top: 40px !important; }
  .pt-xs-45 {
    padding-top: 45px !important; }
  .pt-xs-50 {
    padding-top: 50px !important; }
  .pt-xs-60 {
    padding-top: 60px !important; }
  .pt-xs-65 {
    padding-top: 65px !important; }
  .pt-xs-70 {
    padding-top: 70px !important; }
  .pt-xs-80 {
    padding-top: 80px !important; }
  .pt-xs-90 {
    padding-top: 90px !important; }
  .pt-xs-95 {
    padding-top: 95px !important; }
  .pt-xs-100 {
    padding-top: 100px !important; }
  .pt-xs-110 {
    padding-top: 110px !important; }
  .pt-xs-120 {
    padding-top: 120px !important; }
  .pt-xs-130 {
    padding-top: 130px !important; }
  .pt-xs-140 {
    padding-top: 140px !important; }
  .pt-xs-150 {
    padding-top: 150px !important; }
  .pt-xs-160 {
    padding-top: 160px !important; }
  .pt-xs-170 {
    padding-top: 170px !important; }
  .pt-xs-175 {
    padding-top: 175px !important; }
  .pt-xs-180 {
    padding-top: 180px !important; }
  .pt-xs-190 {
    padding-top: 190px !important; }
  .pt-xs-200 {
    padding-top: 200px !important; }
  .pt-xs-210 {
    padding-top: 210px !important; }
  .pt-xs-220 {
    padding-top: 220px !important; }
  .pt-xs-240 {
    padding-top: 240px !important; }
  .pt-xs-280 {
    padding-top: 280px !important; } }

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-5 {
    padding-top: 5px !important; }
  .pt-sm-8 {
    padding-top: 8px !important; }
  .pt-sm-10 {
    padding-top: 10px !important; }
  .pt-sm-15 {
    padding-top: 15px !important; }
  .pt-sm-18 {
    padding-top: 18px !important; }
  .pt-sm-20 {
    padding-top: 20px !important; }
  .pt-sm-25 {
    padding-top: 25px !important; }
  .pt-sm-30 {
    padding-top: 30px !important; }
  .pt-sm-31 {
    padding-top: 31px !important; }
  .pt-sm-32 {
    padding-top: 32px !important; }
  .pt-sm-33 {
    padding-top: 33px !important; }
  .pt-sm-34 {
    padding-top: 34px !important; }
  .pt-sm-35 {
    padding-top: 35px !important; }
  .pt-sm-36 {
    padding-top: 36px !important; }
  .pt-sm-37 {
    padding-top: 37px !important; }
  .pt-sm-38 {
    padding-top: 38px !important; }
  .pt-sm-39 {
    padding-top: 39px !important; }
  .pt-sm-40 {
    padding-top: 40px !important; }
  .pt-sm-45 {
    padding-top: 45px !important; }
  .pt-sm-50 {
    padding-top: 50px !important; }
  .pt-sm-60 {
    padding-top: 60px !important; }
  .pt-sm-65 {
    padding-top: 65px !important; }
  .pt-sm-70 {
    padding-top: 70px !important; }
  .pt-sm-80 {
    padding-top: 80px !important; }
  .pt-sm-90 {
    padding-top: 90px !important; }
  .pt-sm-95 {
    padding-top: 95px !important; }
  .pt-sm-100 {
    padding-top: 100px !important; }
  .pt-sm-110 {
    padding-top: 110px !important; }
  .pt-sm-120 {
    padding-top: 120px !important; }
  .pt-sm-130 {
    padding-top: 130px !important; }
  .pt-sm-140 {
    padding-top: 140px !important; }
  .pt-sm-150 {
    padding-top: 150px !important; }
  .pt-sm-160 {
    padding-top: 160px !important; }
  .pt-sm-170 {
    padding-top: 170px !important; }
  .pt-sm-175 {
    padding-top: 175px !important; }
  .pt-sm-180 {
    padding-top: 180px !important; }
  .pt-sm-190 {
    padding-top: 190px !important; }
  .pt-sm-200 {
    padding-top: 200px !important; }
  .pt-sm-210 {
    padding-top: 210px !important; }
  .pt-sm-220 {
    padding-top: 220px !important; }
  .pt-sm-240 {
    padding-top: 240px !important; }
  .pt-sm-280 {
    padding-top: 280px !important; } }

@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-5 {
    padding-top: 5px !important; }
  .pt-md-8 {
    padding-top: 8px !important; }
  .pt-md-10 {
    padding-top: 10px !important; }
  .pt-md-15 {
    padding-top: 15px !important; }
  .pt-md-18 {
    padding-top: 18px !important; }
  .pt-md-20 {
    padding-top: 20px !important; }
  .pt-md-25 {
    padding-top: 25px !important; }
  .pt-md-30 {
    padding-top: 30px !important; }
  .pt-md-31 {
    padding-top: 31px !important; }
  .pt-md-32 {
    padding-top: 32px !important; }
  .pt-md-33 {
    padding-top: 33px !important; }
  .pt-md-34 {
    padding-top: 34px !important; }
  .pt-md-35 {
    padding-top: 35px !important; }
  .pt-md-36 {
    padding-top: 36px !important; }
  .pt-md-37 {
    padding-top: 37px !important; }
  .pt-md-38 {
    padding-top: 38px !important; }
  .pt-md-39 {
    padding-top: 39px !important; }
  .pt-md-40 {
    padding-top: 40px !important; }
  .pt-md-45 {
    padding-top: 45px !important; }
  .pt-md-50 {
    padding-top: 50px !important; }
  .pt-md-60 {
    padding-top: 60px !important; }
  .pt-md-65 {
    padding-top: 65px !important; }
  .pt-md-70 {
    padding-top: 70px !important; }
  .pt-md-80 {
    padding-top: 80px !important; }
  .pt-md-90 {
    padding-top: 90px !important; }
  .pt-md-95 {
    padding-top: 95px !important; }
  .pt-md-100 {
    padding-top: 100px !important; }
  .pt-md-110 {
    padding-top: 110px !important; }
  .pt-md-120 {
    padding-top: 120px !important; }
  .pt-md-130 {
    padding-top: 130px !important; }
  .pt-md-140 {
    padding-top: 140px !important; }
  .pt-md-150 {
    padding-top: 150px !important; }
  .pt-md-160 {
    padding-top: 160px !important; }
  .pt-md-170 {
    padding-top: 170px !important; }
  .pt-md-175 {
    padding-top: 175px !important; }
  .pt-md-180 {
    padding-top: 180px !important; }
  .pt-md-190 {
    padding-top: 190px !important; }
  .pt-md-200 {
    padding-top: 200px !important; }
  .pt-md-210 {
    padding-top: 210px !important; }
  .pt-md-220 {
    padding-top: 220px !important; }
  .pt-md-240 {
    padding-top: 240px !important; }
  .pt-md-280 {
    padding-top: 280px !important; } }

@media (min-width: 992px) {
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-5 {
    padding-top: 5px !important; }
  .pt-lg-8 {
    padding-top: 8px !important; }
  .pt-lg-10 {
    padding-top: 10px !important; }
  .pt-lg-15 {
    padding-top: 15px !important; }
  .pt-lg-18 {
    padding-top: 18px !important; }
  .pt-lg-20 {
    padding-top: 20px !important; }
  .pt-lg-25 {
    padding-top: 25px !important; }
  .pt-lg-30 {
    padding-top: 30px !important; }
  .pt-lg-31 {
    padding-top: 31px !important; }
  .pt-lg-32 {
    padding-top: 32px !important; }
  .pt-lg-33 {
    padding-top: 33px !important; }
  .pt-lg-34 {
    padding-top: 34px !important; }
  .pt-lg-35 {
    padding-top: 35px !important; }
  .pt-lg-36 {
    padding-top: 36px !important; }
  .pt-lg-37 {
    padding-top: 37px !important; }
  .pt-lg-38 {
    padding-top: 38px !important; }
  .pt-lg-39 {
    padding-top: 39px !important; }
  .pt-lg-40 {
    padding-top: 40px !important; }
  .pt-lg-45 {
    padding-top: 45px !important; }
  .pt-lg-50 {
    padding-top: 50px !important; }
  .pt-lg-60 {
    padding-top: 60px !important; }
  .pt-lg-65 {
    padding-top: 65px !important; }
  .pt-lg-70 {
    padding-top: 70px !important; }
  .pt-lg-80 {
    padding-top: 80px !important; }
  .pt-lg-90 {
    padding-top: 90px !important; }
  .pt-lg-95 {
    padding-top: 95px !important; }
  .pt-lg-100 {
    padding-top: 100px !important; }
  .pt-lg-110 {
    padding-top: 110px !important; }
  .pt-lg-120 {
    padding-top: 120px !important; }
  .pt-lg-130 {
    padding-top: 130px !important; }
  .pt-lg-140 {
    padding-top: 140px !important; }
  .pt-lg-150 {
    padding-top: 150px !important; }
  .pt-lg-160 {
    padding-top: 160px !important; }
  .pt-lg-170 {
    padding-top: 170px !important; }
  .pt-lg-175 {
    padding-top: 175px !important; }
  .pt-lg-180 {
    padding-top: 180px !important; }
  .pt-lg-190 {
    padding-top: 190px !important; }
  .pt-lg-200 {
    padding-top: 200px !important; }
  .pt-lg-210 {
    padding-top: 210px !important; }
  .pt-lg-220 {
    padding-top: 220px !important; }
  .pt-lg-240 {
    padding-top: 240px !important; }
  .pt-lg-280 {
    padding-top: 280px !important; } }

@media (min-width: 1230px) {
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-5 {
    padding-top: 5px !important; }
  .pt-xl-8 {
    padding-top: 8px !important; }
  .pt-xl-10 {
    padding-top: 10px !important; }
  .pt-xl-15 {
    padding-top: 15px !important; }
  .pt-xl-18 {
    padding-top: 18px !important; }
  .pt-xl-20 {
    padding-top: 20px !important; }
  .pt-xl-25 {
    padding-top: 25px !important; }
  .pt-xl-30 {
    padding-top: 30px !important; }
  .pt-xl-31 {
    padding-top: 31px !important; }
  .pt-xl-32 {
    padding-top: 32px !important; }
  .pt-xl-33 {
    padding-top: 33px !important; }
  .pt-xl-34 {
    padding-top: 34px !important; }
  .pt-xl-35 {
    padding-top: 35px !important; }
  .pt-xl-36 {
    padding-top: 36px !important; }
  .pt-xl-37 {
    padding-top: 37px !important; }
  .pt-xl-38 {
    padding-top: 38px !important; }
  .pt-xl-39 {
    padding-top: 39px !important; }
  .pt-xl-40 {
    padding-top: 40px !important; }
  .pt-xl-45 {
    padding-top: 45px !important; }
  .pt-xl-50 {
    padding-top: 50px !important; }
  .pt-xl-60 {
    padding-top: 60px !important; }
  .pt-xl-65 {
    padding-top: 65px !important; }
  .pt-xl-70 {
    padding-top: 70px !important; }
  .pt-xl-80 {
    padding-top: 80px !important; }
  .pt-xl-90 {
    padding-top: 90px !important; }
  .pt-xl-95 {
    padding-top: 95px !important; }
  .pt-xl-100 {
    padding-top: 100px !important; }
  .pt-xl-110 {
    padding-top: 110px !important; }
  .pt-xl-120 {
    padding-top: 120px !important; }
  .pt-xl-130 {
    padding-top: 130px !important; }
  .pt-xl-140 {
    padding-top: 140px !important; }
  .pt-xl-150 {
    padding-top: 150px !important; }
  .pt-xl-160 {
    padding-top: 160px !important; }
  .pt-xl-170 {
    padding-top: 170px !important; }
  .pt-xl-175 {
    padding-top: 175px !important; }
  .pt-xl-180 {
    padding-top: 180px !important; }
  .pt-xl-190 {
    padding-top: 190px !important; }
  .pt-xl-200 {
    padding-top: 200px !important; }
  .pt-xl-210 {
    padding-top: 210px !important; }
  .pt-xl-220 {
    padding-top: 220px !important; }
  .pt-xl-240 {
    padding-top: 240px !important; }
  .pt-xl-280 {
    padding-top: 280px !important; } }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-8 {
  padding-bottom: 8px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-18 {
  padding-bottom: 18px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-31 {
  padding-bottom: 31px !important; }

.pb-32 {
  padding-bottom: 32px !important; }

.pb-33 {
  padding-bottom: 33px !important; }

.pb-34 {
  padding-bottom: 34px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pb-36 {
  padding-bottom: 36px !important; }

.pb-37 {
  padding-bottom: 37px !important; }

.pb-38 {
  padding-bottom: 38px !important; }

.pb-39 {
  padding-bottom: 39px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-65 {
  padding-bottom: 65px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-95 {
  padding-bottom: 95px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-110 {
  padding-bottom: 110px !important; }

.pb-120 {
  padding-bottom: 120px !important; }

.pb-130 {
  padding-bottom: 130px !important; }

.pb-140 {
  padding-bottom: 140px !important; }

.pb-150 {
  padding-bottom: 150px !important; }

.pb-160 {
  padding-bottom: 160px !important; }

.pb-170 {
  padding-bottom: 170px !important; }

.pb-175 {
  padding-bottom: 175px !important; }

.pb-180 {
  padding-bottom: 180px !important; }

.pb-190 {
  padding-bottom: 190px !important; }

.pb-200 {
  padding-bottom: 200px !important; }

.pb-210 {
  padding-bottom: 210px !important; }

.pb-220 {
  padding-bottom: 220px !important; }

.pb-240 {
  padding-bottom: 240px !important; }

.pb-280 {
  padding-bottom: 280px !important; }

@media (min-width: 1px) {
  .pb-xs-0 {
    padding-bottom: 0 !important; }
  .pb-xs-5 {
    padding-bottom: 5px !important; }
  .pb-xs-8 {
    padding-bottom: 8px !important; }
  .pb-xs-10 {
    padding-bottom: 10px !important; }
  .pb-xs-15 {
    padding-bottom: 15px !important; }
  .pb-xs-18 {
    padding-bottom: 18px !important; }
  .pb-xs-20 {
    padding-bottom: 20px !important; }
  .pb-xs-25 {
    padding-bottom: 25px !important; }
  .pb-xs-30 {
    padding-bottom: 30px !important; }
  .pb-xs-31 {
    padding-bottom: 31px !important; }
  .pb-xs-32 {
    padding-bottom: 32px !important; }
  .pb-xs-33 {
    padding-bottom: 33px !important; }
  .pb-xs-34 {
    padding-bottom: 34px !important; }
  .pb-xs-35 {
    padding-bottom: 35px !important; }
  .pb-xs-36 {
    padding-bottom: 36px !important; }
  .pb-xs-37 {
    padding-bottom: 37px !important; }
  .pb-xs-38 {
    padding-bottom: 38px !important; }
  .pb-xs-39 {
    padding-bottom: 39px !important; }
  .pb-xs-40 {
    padding-bottom: 40px !important; }
  .pb-xs-45 {
    padding-bottom: 45px !important; }
  .pb-xs-50 {
    padding-bottom: 50px !important; }
  .pb-xs-60 {
    padding-bottom: 60px !important; }
  .pb-xs-65 {
    padding-bottom: 65px !important; }
  .pb-xs-70 {
    padding-bottom: 70px !important; }
  .pb-xs-80 {
    padding-bottom: 80px !important; }
  .pb-xs-90 {
    padding-bottom: 90px !important; }
  .pb-xs-95 {
    padding-bottom: 95px !important; }
  .pb-xs-100 {
    padding-bottom: 100px !important; }
  .pb-xs-110 {
    padding-bottom: 110px !important; }
  .pb-xs-120 {
    padding-bottom: 120px !important; }
  .pb-xs-130 {
    padding-bottom: 130px !important; }
  .pb-xs-140 {
    padding-bottom: 140px !important; }
  .pb-xs-150 {
    padding-bottom: 150px !important; }
  .pb-xs-160 {
    padding-bottom: 160px !important; }
  .pb-xs-170 {
    padding-bottom: 170px !important; }
  .pb-xs-175 {
    padding-bottom: 175px !important; }
  .pb-xs-180 {
    padding-bottom: 180px !important; }
  .pb-xs-190 {
    padding-bottom: 190px !important; }
  .pb-xs-200 {
    padding-bottom: 200px !important; }
  .pb-xs-210 {
    padding-bottom: 210px !important; }
  .pb-xs-220 {
    padding-bottom: 220px !important; }
  .pb-xs-240 {
    padding-bottom: 240px !important; }
  .pb-xs-280 {
    padding-bottom: 280px !important; } }

@media (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-5 {
    padding-bottom: 5px !important; }
  .pb-sm-8 {
    padding-bottom: 8px !important; }
  .pb-sm-10 {
    padding-bottom: 10px !important; }
  .pb-sm-15 {
    padding-bottom: 15px !important; }
  .pb-sm-18 {
    padding-bottom: 18px !important; }
  .pb-sm-20 {
    padding-bottom: 20px !important; }
  .pb-sm-25 {
    padding-bottom: 25px !important; }
  .pb-sm-30 {
    padding-bottom: 30px !important; }
  .pb-sm-31 {
    padding-bottom: 31px !important; }
  .pb-sm-32 {
    padding-bottom: 32px !important; }
  .pb-sm-33 {
    padding-bottom: 33px !important; }
  .pb-sm-34 {
    padding-bottom: 34px !important; }
  .pb-sm-35 {
    padding-bottom: 35px !important; }
  .pb-sm-36 {
    padding-bottom: 36px !important; }
  .pb-sm-37 {
    padding-bottom: 37px !important; }
  .pb-sm-38 {
    padding-bottom: 38px !important; }
  .pb-sm-39 {
    padding-bottom: 39px !important; }
  .pb-sm-40 {
    padding-bottom: 40px !important; }
  .pb-sm-45 {
    padding-bottom: 45px !important; }
  .pb-sm-50 {
    padding-bottom: 50px !important; }
  .pb-sm-60 {
    padding-bottom: 60px !important; }
  .pb-sm-65 {
    padding-bottom: 65px !important; }
  .pb-sm-70 {
    padding-bottom: 70px !important; }
  .pb-sm-80 {
    padding-bottom: 80px !important; }
  .pb-sm-90 {
    padding-bottom: 90px !important; }
  .pb-sm-95 {
    padding-bottom: 95px !important; }
  .pb-sm-100 {
    padding-bottom: 100px !important; }
  .pb-sm-110 {
    padding-bottom: 110px !important; }
  .pb-sm-120 {
    padding-bottom: 120px !important; }
  .pb-sm-130 {
    padding-bottom: 130px !important; }
  .pb-sm-140 {
    padding-bottom: 140px !important; }
  .pb-sm-150 {
    padding-bottom: 150px !important; }
  .pb-sm-160 {
    padding-bottom: 160px !important; }
  .pb-sm-170 {
    padding-bottom: 170px !important; }
  .pb-sm-175 {
    padding-bottom: 175px !important; }
  .pb-sm-180 {
    padding-bottom: 180px !important; }
  .pb-sm-190 {
    padding-bottom: 190px !important; }
  .pb-sm-200 {
    padding-bottom: 200px !important; }
  .pb-sm-210 {
    padding-bottom: 210px !important; }
  .pb-sm-220 {
    padding-bottom: 220px !important; }
  .pb-sm-240 {
    padding-bottom: 240px !important; }
  .pb-sm-280 {
    padding-bottom: 280px !important; } }

@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-5 {
    padding-bottom: 5px !important; }
  .pb-md-8 {
    padding-bottom: 8px !important; }
  .pb-md-10 {
    padding-bottom: 10px !important; }
  .pb-md-15 {
    padding-bottom: 15px !important; }
  .pb-md-18 {
    padding-bottom: 18px !important; }
  .pb-md-20 {
    padding-bottom: 20px !important; }
  .pb-md-25 {
    padding-bottom: 25px !important; }
  .pb-md-30 {
    padding-bottom: 30px !important; }
  .pb-md-31 {
    padding-bottom: 31px !important; }
  .pb-md-32 {
    padding-bottom: 32px !important; }
  .pb-md-33 {
    padding-bottom: 33px !important; }
  .pb-md-34 {
    padding-bottom: 34px !important; }
  .pb-md-35 {
    padding-bottom: 35px !important; }
  .pb-md-36 {
    padding-bottom: 36px !important; }
  .pb-md-37 {
    padding-bottom: 37px !important; }
  .pb-md-38 {
    padding-bottom: 38px !important; }
  .pb-md-39 {
    padding-bottom: 39px !important; }
  .pb-md-40 {
    padding-bottom: 40px !important; }
  .pb-md-45 {
    padding-bottom: 45px !important; }
  .pb-md-50 {
    padding-bottom: 50px !important; }
  .pb-md-60 {
    padding-bottom: 60px !important; }
  .pb-md-65 {
    padding-bottom: 65px !important; }
  .pb-md-70 {
    padding-bottom: 70px !important; }
  .pb-md-80 {
    padding-bottom: 80px !important; }
  .pb-md-90 {
    padding-bottom: 90px !important; }
  .pb-md-95 {
    padding-bottom: 95px !important; }
  .pb-md-100 {
    padding-bottom: 100px !important; }
  .pb-md-110 {
    padding-bottom: 110px !important; }
  .pb-md-120 {
    padding-bottom: 120px !important; }
  .pb-md-130 {
    padding-bottom: 130px !important; }
  .pb-md-140 {
    padding-bottom: 140px !important; }
  .pb-md-150 {
    padding-bottom: 150px !important; }
  .pb-md-160 {
    padding-bottom: 160px !important; }
  .pb-md-170 {
    padding-bottom: 170px !important; }
  .pb-md-175 {
    padding-bottom: 175px !important; }
  .pb-md-180 {
    padding-bottom: 180px !important; }
  .pb-md-190 {
    padding-bottom: 190px !important; }
  .pb-md-200 {
    padding-bottom: 200px !important; }
  .pb-md-210 {
    padding-bottom: 210px !important; }
  .pb-md-220 {
    padding-bottom: 220px !important; }
  .pb-md-240 {
    padding-bottom: 240px !important; }
  .pb-md-280 {
    padding-bottom: 280px !important; } }

@media (min-width: 992px) {
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-5 {
    padding-bottom: 5px !important; }
  .pb-lg-8 {
    padding-bottom: 8px !important; }
  .pb-lg-10 {
    padding-bottom: 10px !important; }
  .pb-lg-15 {
    padding-bottom: 15px !important; }
  .pb-lg-18 {
    padding-bottom: 18px !important; }
  .pb-lg-20 {
    padding-bottom: 20px !important; }
  .pb-lg-25 {
    padding-bottom: 25px !important; }
  .pb-lg-30 {
    padding-bottom: 30px !important; }
  .pb-lg-31 {
    padding-bottom: 31px !important; }
  .pb-lg-32 {
    padding-bottom: 32px !important; }
  .pb-lg-33 {
    padding-bottom: 33px !important; }
  .pb-lg-34 {
    padding-bottom: 34px !important; }
  .pb-lg-35 {
    padding-bottom: 35px !important; }
  .pb-lg-36 {
    padding-bottom: 36px !important; }
  .pb-lg-37 {
    padding-bottom: 37px !important; }
  .pb-lg-38 {
    padding-bottom: 38px !important; }
  .pb-lg-39 {
    padding-bottom: 39px !important; }
  .pb-lg-40 {
    padding-bottom: 40px !important; }
  .pb-lg-45 {
    padding-bottom: 45px !important; }
  .pb-lg-50 {
    padding-bottom: 50px !important; }
  .pb-lg-60 {
    padding-bottom: 60px !important; }
  .pb-lg-65 {
    padding-bottom: 65px !important; }
  .pb-lg-70 {
    padding-bottom: 70px !important; }
  .pb-lg-80 {
    padding-bottom: 80px !important; }
  .pb-lg-90 {
    padding-bottom: 90px !important; }
  .pb-lg-95 {
    padding-bottom: 95px !important; }
  .pb-lg-100 {
    padding-bottom: 100px !important; }
  .pb-lg-110 {
    padding-bottom: 110px !important; }
  .pb-lg-120 {
    padding-bottom: 120px !important; }
  .pb-lg-130 {
    padding-bottom: 130px !important; }
  .pb-lg-140 {
    padding-bottom: 140px !important; }
  .pb-lg-150 {
    padding-bottom: 150px !important; }
  .pb-lg-160 {
    padding-bottom: 160px !important; }
  .pb-lg-170 {
    padding-bottom: 170px !important; }
  .pb-lg-175 {
    padding-bottom: 175px !important; }
  .pb-lg-180 {
    padding-bottom: 180px !important; }
  .pb-lg-190 {
    padding-bottom: 190px !important; }
  .pb-lg-200 {
    padding-bottom: 200px !important; }
  .pb-lg-210 {
    padding-bottom: 210px !important; }
  .pb-lg-220 {
    padding-bottom: 220px !important; }
  .pb-lg-240 {
    padding-bottom: 240px !important; }
  .pb-lg-280 {
    padding-bottom: 280px !important; } }

@media (min-width: 1230px) {
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-5 {
    padding-bottom: 5px !important; }
  .pb-xl-8 {
    padding-bottom: 8px !important; }
  .pb-xl-10 {
    padding-bottom: 10px !important; }
  .pb-xl-15 {
    padding-bottom: 15px !important; }
  .pb-xl-18 {
    padding-bottom: 18px !important; }
  .pb-xl-20 {
    padding-bottom: 20px !important; }
  .pb-xl-25 {
    padding-bottom: 25px !important; }
  .pb-xl-30 {
    padding-bottom: 30px !important; }
  .pb-xl-31 {
    padding-bottom: 31px !important; }
  .pb-xl-32 {
    padding-bottom: 32px !important; }
  .pb-xl-33 {
    padding-bottom: 33px !important; }
  .pb-xl-34 {
    padding-bottom: 34px !important; }
  .pb-xl-35 {
    padding-bottom: 35px !important; }
  .pb-xl-36 {
    padding-bottom: 36px !important; }
  .pb-xl-37 {
    padding-bottom: 37px !important; }
  .pb-xl-38 {
    padding-bottom: 38px !important; }
  .pb-xl-39 {
    padding-bottom: 39px !important; }
  .pb-xl-40 {
    padding-bottom: 40px !important; }
  .pb-xl-45 {
    padding-bottom: 45px !important; }
  .pb-xl-50 {
    padding-bottom: 50px !important; }
  .pb-xl-60 {
    padding-bottom: 60px !important; }
  .pb-xl-65 {
    padding-bottom: 65px !important; }
  .pb-xl-70 {
    padding-bottom: 70px !important; }
  .pb-xl-80 {
    padding-bottom: 80px !important; }
  .pb-xl-90 {
    padding-bottom: 90px !important; }
  .pb-xl-95 {
    padding-bottom: 95px !important; }
  .pb-xl-100 {
    padding-bottom: 100px !important; }
  .pb-xl-110 {
    padding-bottom: 110px !important; }
  .pb-xl-120 {
    padding-bottom: 120px !important; }
  .pb-xl-130 {
    padding-bottom: 130px !important; }
  .pb-xl-140 {
    padding-bottom: 140px !important; }
  .pb-xl-150 {
    padding-bottom: 150px !important; }
  .pb-xl-160 {
    padding-bottom: 160px !important; }
  .pb-xl-170 {
    padding-bottom: 170px !important; }
  .pb-xl-175 {
    padding-bottom: 175px !important; }
  .pb-xl-180 {
    padding-bottom: 180px !important; }
  .pb-xl-190 {
    padding-bottom: 190px !important; }
  .pb-xl-200 {
    padding-bottom: 200px !important; }
  .pb-xl-210 {
    padding-bottom: 210px !important; }
  .pb-xl-220 {
    padding-bottom: 220px !important; }
  .pb-xl-240 {
    padding-bottom: 240px !important; }
  .pb-xl-280 {
    padding-bottom: 280px !important; } }

.pr-0 {
  padding-right: 0 !important; }

.pr-5 {
  padding-right: 5px !important; }

.pr-8 {
  padding-right: 8px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-18 {
  padding-right: 18px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-31 {
  padding-right: 31px !important; }

.pr-32 {
  padding-right: 32px !important; }

.pr-33 {
  padding-right: 33px !important; }

.pr-34 {
  padding-right: 34px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pr-36 {
  padding-right: 36px !important; }

.pr-37 {
  padding-right: 37px !important; }

.pr-38 {
  padding-right: 38px !important; }

.pr-39 {
  padding-right: 39px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pr-45 {
  padding-right: 45px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pr-65 {
  padding-right: 65px !important; }

.pr-70 {
  padding-right: 70px !important; }

.pr-90 {
  padding-right: 90px !important; }

.pr-95 {
  padding-right: 95px !important; }

@media (min-width: 1px) {
  .pr-xs-0 {
    padding-right: 0 !important; }
  .pr-xs-5 {
    padding-right: 5px !important; }
  .pr-xs-8 {
    padding-right: 8px !important; }
  .pr-xs-10 {
    padding-right: 10px !important; }
  .pr-xs-15 {
    padding-right: 15px !important; }
  .pr-xs-18 {
    padding-right: 18px !important; }
  .pr-xs-20 {
    padding-right: 20px !important; }
  .pr-xs-25 {
    padding-right: 25px !important; }
  .pr-xs-30 {
    padding-right: 30px !important; }
  .pr-xs-31 {
    padding-right: 31px !important; }
  .pr-xs-32 {
    padding-right: 32px !important; }
  .pr-xs-33 {
    padding-right: 33px !important; }
  .pr-xs-34 {
    padding-right: 34px !important; }
  .pr-xs-35 {
    padding-right: 35px !important; }
  .pr-xs-36 {
    padding-right: 36px !important; }
  .pr-xs-37 {
    padding-right: 37px !important; }
  .pr-xs-38 {
    padding-right: 38px !important; }
  .pr-xs-39 {
    padding-right: 39px !important; }
  .pr-xs-40 {
    padding-right: 40px !important; }
  .pr-xs-45 {
    padding-right: 45px !important; }
  .pr-xs-50 {
    padding-right: 50px !important; }
  .pr-xs-60 {
    padding-right: 60px !important; }
  .pr-xs-65 {
    padding-right: 65px !important; }
  .pr-xs-70 {
    padding-right: 70px !important; }
  .pr-xs-90 {
    padding-right: 90px !important; }
  .pr-xs-95 {
    padding-right: 95px !important; } }

@media (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-5 {
    padding-right: 5px !important; }
  .pr-sm-8 {
    padding-right: 8px !important; }
  .pr-sm-10 {
    padding-right: 10px !important; }
  .pr-sm-15 {
    padding-right: 15px !important; }
  .pr-sm-18 {
    padding-right: 18px !important; }
  .pr-sm-20 {
    padding-right: 20px !important; }
  .pr-sm-25 {
    padding-right: 25px !important; }
  .pr-sm-30 {
    padding-right: 30px !important; }
  .pr-sm-31 {
    padding-right: 31px !important; }
  .pr-sm-32 {
    padding-right: 32px !important; }
  .pr-sm-33 {
    padding-right: 33px !important; }
  .pr-sm-34 {
    padding-right: 34px !important; }
  .pr-sm-35 {
    padding-right: 35px !important; }
  .pr-sm-36 {
    padding-right: 36px !important; }
  .pr-sm-37 {
    padding-right: 37px !important; }
  .pr-sm-38 {
    padding-right: 38px !important; }
  .pr-sm-39 {
    padding-right: 39px !important; }
  .pr-sm-40 {
    padding-right: 40px !important; }
  .pr-sm-45 {
    padding-right: 45px !important; }
  .pr-sm-50 {
    padding-right: 50px !important; }
  .pr-sm-60 {
    padding-right: 60px !important; }
  .pr-sm-65 {
    padding-right: 65px !important; }
  .pr-sm-70 {
    padding-right: 70px !important; }
  .pr-sm-90 {
    padding-right: 90px !important; }
  .pr-sm-95 {
    padding-right: 95px !important; } }

@media (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-5 {
    padding-right: 5px !important; }
  .pr-md-8 {
    padding-right: 8px !important; }
  .pr-md-10 {
    padding-right: 10px !important; }
  .pr-md-15 {
    padding-right: 15px !important; }
  .pr-md-18 {
    padding-right: 18px !important; }
  .pr-md-20 {
    padding-right: 20px !important; }
  .pr-md-25 {
    padding-right: 25px !important; }
  .pr-md-30 {
    padding-right: 30px !important; }
  .pr-md-31 {
    padding-right: 31px !important; }
  .pr-md-32 {
    padding-right: 32px !important; }
  .pr-md-33 {
    padding-right: 33px !important; }
  .pr-md-34 {
    padding-right: 34px !important; }
  .pr-md-35 {
    padding-right: 35px !important; }
  .pr-md-36 {
    padding-right: 36px !important; }
  .pr-md-37 {
    padding-right: 37px !important; }
  .pr-md-38 {
    padding-right: 38px !important; }
  .pr-md-39 {
    padding-right: 39px !important; }
  .pr-md-40 {
    padding-right: 40px !important; }
  .pr-md-45 {
    padding-right: 45px !important; }
  .pr-md-50 {
    padding-right: 50px !important; }
  .pr-md-60 {
    padding-right: 60px !important; }
  .pr-md-65 {
    padding-right: 65px !important; }
  .pr-md-70 {
    padding-right: 70px !important; }
  .pr-md-90 {
    padding-right: 90px !important; }
  .pr-md-95 {
    padding-right: 95px !important; } }

@media (min-width: 992px) {
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-5 {
    padding-right: 5px !important; }
  .pr-lg-8 {
    padding-right: 8px !important; }
  .pr-lg-10 {
    padding-right: 10px !important; }
  .pr-lg-15 {
    padding-right: 15px !important; }
  .pr-lg-18 {
    padding-right: 18px !important; }
  .pr-lg-20 {
    padding-right: 20px !important; }
  .pr-lg-25 {
    padding-right: 25px !important; }
  .pr-lg-30 {
    padding-right: 30px !important; }
  .pr-lg-31 {
    padding-right: 31px !important; }
  .pr-lg-32 {
    padding-right: 32px !important; }
  .pr-lg-33 {
    padding-right: 33px !important; }
  .pr-lg-34 {
    padding-right: 34px !important; }
  .pr-lg-35 {
    padding-right: 35px !important; }
  .pr-lg-36 {
    padding-right: 36px !important; }
  .pr-lg-37 {
    padding-right: 37px !important; }
  .pr-lg-38 {
    padding-right: 38px !important; }
  .pr-lg-39 {
    padding-right: 39px !important; }
  .pr-lg-40 {
    padding-right: 40px !important; }
  .pr-lg-45 {
    padding-right: 45px !important; }
  .pr-lg-50 {
    padding-right: 50px !important; }
  .pr-lg-60 {
    padding-right: 60px !important; }
  .pr-lg-65 {
    padding-right: 65px !important; }
  .pr-lg-70 {
    padding-right: 70px !important; }
  .pr-lg-90 {
    padding-right: 90px !important; }
  .pr-lg-95 {
    padding-right: 95px !important; } }

@media (min-width: 1230px) {
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-5 {
    padding-right: 5px !important; }
  .pr-xl-8 {
    padding-right: 8px !important; }
  .pr-xl-10 {
    padding-right: 10px !important; }
  .pr-xl-15 {
    padding-right: 15px !important; }
  .pr-xl-18 {
    padding-right: 18px !important; }
  .pr-xl-20 {
    padding-right: 20px !important; }
  .pr-xl-25 {
    padding-right: 25px !important; }
  .pr-xl-30 {
    padding-right: 30px !important; }
  .pr-xl-31 {
    padding-right: 31px !important; }
  .pr-xl-32 {
    padding-right: 32px !important; }
  .pr-xl-33 {
    padding-right: 33px !important; }
  .pr-xl-34 {
    padding-right: 34px !important; }
  .pr-xl-35 {
    padding-right: 35px !important; }
  .pr-xl-36 {
    padding-right: 36px !important; }
  .pr-xl-37 {
    padding-right: 37px !important; }
  .pr-xl-38 {
    padding-right: 38px !important; }
  .pr-xl-39 {
    padding-right: 39px !important; }
  .pr-xl-40 {
    padding-right: 40px !important; }
  .pr-xl-45 {
    padding-right: 45px !important; }
  .pr-xl-50 {
    padding-right: 50px !important; }
  .pr-xl-60 {
    padding-right: 60px !important; }
  .pr-xl-65 {
    padding-right: 65px !important; }
  .pr-xl-70 {
    padding-right: 70px !important; }
  .pr-xl-90 {
    padding-right: 90px !important; }
  .pr-xl-95 {
    padding-right: 95px !important; } }

.pl-0 {
  padding-left: 0 !important; }

.pl-5 {
  padding-left: 5px !important; }

.pl-8 {
  padding-left: 8px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-18 {
  padding-left: 18px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pl-31 {
  padding-left: 31px !important; }

.pl-32 {
  padding-left: 32px !important; }

.pl-33 {
  padding-left: 33px !important; }

.pl-34 {
  padding-left: 34px !important; }

.pl-35 {
  padding-left: 35px !important; }

.pl-36 {
  padding-left: 36px !important; }

.pl-37 {
  padding-left: 37px !important; }

.pl-38 {
  padding-left: 38px !important; }

.pl-39 {
  padding-left: 39px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pl-45 {
  padding-left: 45px !important; }

.pl-50 {
  padding-left: 50px !important; }

.pl-60 {
  padding-left: 60px !important; }

.pl-65 {
  padding-left: 65px !important; }

.pl-70 {
  padding-left: 70px !important; }

.pl-90 {
  padding-left: 90px !important; }

.pl-95 {
  padding-left: 95px !important; }

@media (min-width: 1px) {
  .pl-xs-0 {
    padding-left: 0 !important; }
  .pl-xs-5 {
    padding-left: 5px !important; }
  .pl-xs-8 {
    padding-left: 8px !important; }
  .pl-xs-10 {
    padding-left: 10px !important; }
  .pl-xs-15 {
    padding-left: 15px !important; }
  .pl-xs-18 {
    padding-left: 18px !important; }
  .pl-xs-20 {
    padding-left: 20px !important; }
  .pl-xs-25 {
    padding-left: 25px !important; }
  .pl-xs-30 {
    padding-left: 30px !important; }
  .pl-xs-31 {
    padding-left: 31px !important; }
  .pl-xs-32 {
    padding-left: 32px !important; }
  .pl-xs-33 {
    padding-left: 33px !important; }
  .pl-xs-34 {
    padding-left: 34px !important; }
  .pl-xs-35 {
    padding-left: 35px !important; }
  .pl-xs-36 {
    padding-left: 36px !important; }
  .pl-xs-37 {
    padding-left: 37px !important; }
  .pl-xs-38 {
    padding-left: 38px !important; }
  .pl-xs-39 {
    padding-left: 39px !important; }
  .pl-xs-40 {
    padding-left: 40px !important; }
  .pl-xs-45 {
    padding-left: 45px !important; }
  .pl-xs-50 {
    padding-left: 50px !important; }
  .pl-xs-60 {
    padding-left: 60px !important; }
  .pl-xs-65 {
    padding-left: 65px !important; }
  .pl-xs-70 {
    padding-left: 70px !important; }
  .pl-xs-90 {
    padding-left: 90px !important; }
  .pl-xs-95 {
    padding-left: 95px !important; } }

@media (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-5 {
    padding-left: 5px !important; }
  .pl-sm-8 {
    padding-left: 8px !important; }
  .pl-sm-10 {
    padding-left: 10px !important; }
  .pl-sm-15 {
    padding-left: 15px !important; }
  .pl-sm-18 {
    padding-left: 18px !important; }
  .pl-sm-20 {
    padding-left: 20px !important; }
  .pl-sm-25 {
    padding-left: 25px !important; }
  .pl-sm-30 {
    padding-left: 30px !important; }
  .pl-sm-31 {
    padding-left: 31px !important; }
  .pl-sm-32 {
    padding-left: 32px !important; }
  .pl-sm-33 {
    padding-left: 33px !important; }
  .pl-sm-34 {
    padding-left: 34px !important; }
  .pl-sm-35 {
    padding-left: 35px !important; }
  .pl-sm-36 {
    padding-left: 36px !important; }
  .pl-sm-37 {
    padding-left: 37px !important; }
  .pl-sm-38 {
    padding-left: 38px !important; }
  .pl-sm-39 {
    padding-left: 39px !important; }
  .pl-sm-40 {
    padding-left: 40px !important; }
  .pl-sm-45 {
    padding-left: 45px !important; }
  .pl-sm-50 {
    padding-left: 50px !important; }
  .pl-sm-60 {
    padding-left: 60px !important; }
  .pl-sm-65 {
    padding-left: 65px !important; }
  .pl-sm-70 {
    padding-left: 70px !important; }
  .pl-sm-90 {
    padding-left: 90px !important; }
  .pl-sm-95 {
    padding-left: 95px !important; } }

@media (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-5 {
    padding-left: 5px !important; }
  .pl-md-8 {
    padding-left: 8px !important; }
  .pl-md-10 {
    padding-left: 10px !important; }
  .pl-md-15 {
    padding-left: 15px !important; }
  .pl-md-18 {
    padding-left: 18px !important; }
  .pl-md-20 {
    padding-left: 20px !important; }
  .pl-md-25 {
    padding-left: 25px !important; }
  .pl-md-30 {
    padding-left: 30px !important; }
  .pl-md-31 {
    padding-left: 31px !important; }
  .pl-md-32 {
    padding-left: 32px !important; }
  .pl-md-33 {
    padding-left: 33px !important; }
  .pl-md-34 {
    padding-left: 34px !important; }
  .pl-md-35 {
    padding-left: 35px !important; }
  .pl-md-36 {
    padding-left: 36px !important; }
  .pl-md-37 {
    padding-left: 37px !important; }
  .pl-md-38 {
    padding-left: 38px !important; }
  .pl-md-39 {
    padding-left: 39px !important; }
  .pl-md-40 {
    padding-left: 40px !important; }
  .pl-md-45 {
    padding-left: 45px !important; }
  .pl-md-50 {
    padding-left: 50px !important; }
  .pl-md-60 {
    padding-left: 60px !important; }
  .pl-md-65 {
    padding-left: 65px !important; }
  .pl-md-70 {
    padding-left: 70px !important; }
  .pl-md-90 {
    padding-left: 90px !important; }
  .pl-md-95 {
    padding-left: 95px !important; } }

@media (min-width: 992px) {
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-5 {
    padding-left: 5px !important; }
  .pl-lg-8 {
    padding-left: 8px !important; }
  .pl-lg-10 {
    padding-left: 10px !important; }
  .pl-lg-15 {
    padding-left: 15px !important; }
  .pl-lg-18 {
    padding-left: 18px !important; }
  .pl-lg-20 {
    padding-left: 20px !important; }
  .pl-lg-25 {
    padding-left: 25px !important; }
  .pl-lg-30 {
    padding-left: 30px !important; }
  .pl-lg-31 {
    padding-left: 31px !important; }
  .pl-lg-32 {
    padding-left: 32px !important; }
  .pl-lg-33 {
    padding-left: 33px !important; }
  .pl-lg-34 {
    padding-left: 34px !important; }
  .pl-lg-35 {
    padding-left: 35px !important; }
  .pl-lg-36 {
    padding-left: 36px !important; }
  .pl-lg-37 {
    padding-left: 37px !important; }
  .pl-lg-38 {
    padding-left: 38px !important; }
  .pl-lg-39 {
    padding-left: 39px !important; }
  .pl-lg-40 {
    padding-left: 40px !important; }
  .pl-lg-45 {
    padding-left: 45px !important; }
  .pl-lg-50 {
    padding-left: 50px !important; }
  .pl-lg-60 {
    padding-left: 60px !important; }
  .pl-lg-65 {
    padding-left: 65px !important; }
  .pl-lg-70 {
    padding-left: 70px !important; }
  .pl-lg-90 {
    padding-left: 90px !important; }
  .pl-lg-95 {
    padding-left: 95px !important; } }

@media (min-width: 1230px) {
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-5 {
    padding-left: 5px !important; }
  .pl-xl-8 {
    padding-left: 8px !important; }
  .pl-xl-10 {
    padding-left: 10px !important; }
  .pl-xl-15 {
    padding-left: 15px !important; }
  .pl-xl-18 {
    padding-left: 18px !important; }
  .pl-xl-20 {
    padding-left: 20px !important; }
  .pl-xl-25 {
    padding-left: 25px !important; }
  .pl-xl-30 {
    padding-left: 30px !important; }
  .pl-xl-31 {
    padding-left: 31px !important; }
  .pl-xl-32 {
    padding-left: 32px !important; }
  .pl-xl-33 {
    padding-left: 33px !important; }
  .pl-xl-34 {
    padding-left: 34px !important; }
  .pl-xl-35 {
    padding-left: 35px !important; }
  .pl-xl-36 {
    padding-left: 36px !important; }
  .pl-xl-37 {
    padding-left: 37px !important; }
  .pl-xl-38 {
    padding-left: 38px !important; }
  .pl-xl-39 {
    padding-left: 39px !important; }
  .pl-xl-40 {
    padding-left: 40px !important; }
  .pl-xl-45 {
    padding-left: 45px !important; }
  .pl-xl-50 {
    padding-left: 50px !important; }
  .pl-xl-60 {
    padding-left: 60px !important; }
  .pl-xl-65 {
    padding-left: 65px !important; }
  .pl-xl-70 {
    padding-left: 70px !important; }
  .pl-xl-90 {
    padding-left: 90px !important; }
  .pl-xl-95 {
    padding-left: 95px !important; } }

.mt--50 {
  margin-top: -50px !important; }

.mt--30 {
  margin-top: -30px !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-8 {
  margin-top: 8px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-18 {
  margin-top: 18px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-31 {
  margin-top: 31px !important; }

.mt-32 {
  margin-top: 32px !important; }

.mt-33 {
  margin-top: 33px !important; }

.mt-34 {
  margin-top: 34px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mt-36 {
  margin-top: 36px !important; }

.mt-37 {
  margin-top: 37px !important; }

.mt-38 {
  margin-top: 38px !important; }

.mt-39 {
  margin-top: 39px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-110 {
  margin-top: 110px !important; }

.mt-120 {
  margin-top: 120px !important; }

.mt-130 {
  margin-top: 130px !important; }

.mt-140 {
  margin-top: 140px !important; }

.mt-150 {
  margin-top: 150px !important; }

.mt-160 {
  margin-top: 160px !important; }

.mt-170 {
  margin-top: 170px !important; }

.mt-175 {
  margin-top: 175px !important; }

.mt-180 {
  margin-top: 180px !important; }

.mt-190 {
  margin-top: 190px !important; }

.mt-200 {
  margin-top: 200px !important; }

.mt-210 {
  margin-top: 210px !important; }

.mt-250 {
  margin-top: 250px !important; }

.mt-280 {
  margin-top: 280px !important; }

@media (min-width: 1px) {
  .mt-xs--50 {
    margin-top: -50px !important; }
  .mt-xs--30 {
    margin-top: -30px !important; }
  .mt-xs-0 {
    margin-top: 0 !important; }
  .mt-xs-8 {
    margin-top: 8px !important; }
  .mt-xs-10 {
    margin-top: 10px !important; }
  .mt-xs-15 {
    margin-top: 15px !important; }
  .mt-xs-18 {
    margin-top: 18px !important; }
  .mt-xs-20 {
    margin-top: 20px !important; }
  .mt-xs-25 {
    margin-top: 25px !important; }
  .mt-xs-30 {
    margin-top: 30px !important; }
  .mt-xs-31 {
    margin-top: 31px !important; }
  .mt-xs-32 {
    margin-top: 32px !important; }
  .mt-xs-33 {
    margin-top: 33px !important; }
  .mt-xs-34 {
    margin-top: 34px !important; }
  .mt-xs-35 {
    margin-top: 35px !important; }
  .mt-xs-36 {
    margin-top: 36px !important; }
  .mt-xs-37 {
    margin-top: 37px !important; }
  .mt-xs-38 {
    margin-top: 38px !important; }
  .mt-xs-39 {
    margin-top: 39px !important; }
  .mt-xs-40 {
    margin-top: 40px !important; }
  .mt-xs-45 {
    margin-top: 45px !important; }
  .mt-xs-50 {
    margin-top: 50px !important; }
  .mt-xs-60 {
    margin-top: 60px !important; }
  .mt-xs-65 {
    margin-top: 65px !important; }
  .mt-xs-70 {
    margin-top: 70px !important; }
  .mt-xs-80 {
    margin-top: 80px !important; }
  .mt-xs-90 {
    margin-top: 90px !important; }
  .mt-xs-95 {
    margin-top: 95px !important; }
  .mt-xs-100 {
    margin-top: 100px !important; }
  .mt-xs-110 {
    margin-top: 110px !important; }
  .mt-xs-120 {
    margin-top: 120px !important; }
  .mt-xs-130 {
    margin-top: 130px !important; }
  .mt-xs-140 {
    margin-top: 140px !important; }
  .mt-xs-150 {
    margin-top: 150px !important; }
  .mt-xs-160 {
    margin-top: 160px !important; }
  .mt-xs-170 {
    margin-top: 170px !important; }
  .mt-xs-175 {
    margin-top: 175px !important; }
  .mt-xs-180 {
    margin-top: 180px !important; }
  .mt-xs-190 {
    margin-top: 190px !important; }
  .mt-xs-200 {
    margin-top: 200px !important; }
  .mt-xs-210 {
    margin-top: 210px !important; }
  .mt-xs-250 {
    margin-top: 250px !important; }
  .mt-xs-280 {
    margin-top: 280px !important; } }

@media (min-width: 576px) {
  .mt-sm--50 {
    margin-top: -50px !important; }
  .mt-sm--30 {
    margin-top: -30px !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-8 {
    margin-top: 8px !important; }
  .mt-sm-10 {
    margin-top: 10px !important; }
  .mt-sm-15 {
    margin-top: 15px !important; }
  .mt-sm-18 {
    margin-top: 18px !important; }
  .mt-sm-20 {
    margin-top: 20px !important; }
  .mt-sm-25 {
    margin-top: 25px !important; }
  .mt-sm-30 {
    margin-top: 30px !important; }
  .mt-sm-31 {
    margin-top: 31px !important; }
  .mt-sm-32 {
    margin-top: 32px !important; }
  .mt-sm-33 {
    margin-top: 33px !important; }
  .mt-sm-34 {
    margin-top: 34px !important; }
  .mt-sm-35 {
    margin-top: 35px !important; }
  .mt-sm-36 {
    margin-top: 36px !important; }
  .mt-sm-37 {
    margin-top: 37px !important; }
  .mt-sm-38 {
    margin-top: 38px !important; }
  .mt-sm-39 {
    margin-top: 39px !important; }
  .mt-sm-40 {
    margin-top: 40px !important; }
  .mt-sm-45 {
    margin-top: 45px !important; }
  .mt-sm-50 {
    margin-top: 50px !important; }
  .mt-sm-60 {
    margin-top: 60px !important; }
  .mt-sm-65 {
    margin-top: 65px !important; }
  .mt-sm-70 {
    margin-top: 70px !important; }
  .mt-sm-80 {
    margin-top: 80px !important; }
  .mt-sm-90 {
    margin-top: 90px !important; }
  .mt-sm-95 {
    margin-top: 95px !important; }
  .mt-sm-100 {
    margin-top: 100px !important; }
  .mt-sm-110 {
    margin-top: 110px !important; }
  .mt-sm-120 {
    margin-top: 120px !important; }
  .mt-sm-130 {
    margin-top: 130px !important; }
  .mt-sm-140 {
    margin-top: 140px !important; }
  .mt-sm-150 {
    margin-top: 150px !important; }
  .mt-sm-160 {
    margin-top: 160px !important; }
  .mt-sm-170 {
    margin-top: 170px !important; }
  .mt-sm-175 {
    margin-top: 175px !important; }
  .mt-sm-180 {
    margin-top: 180px !important; }
  .mt-sm-190 {
    margin-top: 190px !important; }
  .mt-sm-200 {
    margin-top: 200px !important; }
  .mt-sm-210 {
    margin-top: 210px !important; }
  .mt-sm-250 {
    margin-top: 250px !important; }
  .mt-sm-280 {
    margin-top: 280px !important; } }

@media (min-width: 768px) {
  .mt-md--50 {
    margin-top: -50px !important; }
  .mt-md--30 {
    margin-top: -30px !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-8 {
    margin-top: 8px !important; }
  .mt-md-10 {
    margin-top: 10px !important; }
  .mt-md-15 {
    margin-top: 15px !important; }
  .mt-md-18 {
    margin-top: 18px !important; }
  .mt-md-20 {
    margin-top: 20px !important; }
  .mt-md-25 {
    margin-top: 25px !important; }
  .mt-md-30 {
    margin-top: 30px !important; }
  .mt-md-31 {
    margin-top: 31px !important; }
  .mt-md-32 {
    margin-top: 32px !important; }
  .mt-md-33 {
    margin-top: 33px !important; }
  .mt-md-34 {
    margin-top: 34px !important; }
  .mt-md-35 {
    margin-top: 35px !important; }
  .mt-md-36 {
    margin-top: 36px !important; }
  .mt-md-37 {
    margin-top: 37px !important; }
  .mt-md-38 {
    margin-top: 38px !important; }
  .mt-md-39 {
    margin-top: 39px !important; }
  .mt-md-40 {
    margin-top: 40px !important; }
  .mt-md-45 {
    margin-top: 45px !important; }
  .mt-md-50 {
    margin-top: 50px !important; }
  .mt-md-60 {
    margin-top: 60px !important; }
  .mt-md-65 {
    margin-top: 65px !important; }
  .mt-md-70 {
    margin-top: 70px !important; }
  .mt-md-80 {
    margin-top: 80px !important; }
  .mt-md-90 {
    margin-top: 90px !important; }
  .mt-md-95 {
    margin-top: 95px !important; }
  .mt-md-100 {
    margin-top: 100px !important; }
  .mt-md-110 {
    margin-top: 110px !important; }
  .mt-md-120 {
    margin-top: 120px !important; }
  .mt-md-130 {
    margin-top: 130px !important; }
  .mt-md-140 {
    margin-top: 140px !important; }
  .mt-md-150 {
    margin-top: 150px !important; }
  .mt-md-160 {
    margin-top: 160px !important; }
  .mt-md-170 {
    margin-top: 170px !important; }
  .mt-md-175 {
    margin-top: 175px !important; }
  .mt-md-180 {
    margin-top: 180px !important; }
  .mt-md-190 {
    margin-top: 190px !important; }
  .mt-md-200 {
    margin-top: 200px !important; }
  .mt-md-210 {
    margin-top: 210px !important; }
  .mt-md-250 {
    margin-top: 250px !important; }
  .mt-md-280 {
    margin-top: 280px !important; } }

@media (min-width: 992px) {
  .mt-lg--50 {
    margin-top: -50px !important; }
  .mt-lg--30 {
    margin-top: -30px !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-8 {
    margin-top: 8px !important; }
  .mt-lg-10 {
    margin-top: 10px !important; }
  .mt-lg-15 {
    margin-top: 15px !important; }
  .mt-lg-18 {
    margin-top: 18px !important; }
  .mt-lg-20 {
    margin-top: 20px !important; }
  .mt-lg-25 {
    margin-top: 25px !important; }
  .mt-lg-30 {
    margin-top: 30px !important; }
  .mt-lg-31 {
    margin-top: 31px !important; }
  .mt-lg-32 {
    margin-top: 32px !important; }
  .mt-lg-33 {
    margin-top: 33px !important; }
  .mt-lg-34 {
    margin-top: 34px !important; }
  .mt-lg-35 {
    margin-top: 35px !important; }
  .mt-lg-36 {
    margin-top: 36px !important; }
  .mt-lg-37 {
    margin-top: 37px !important; }
  .mt-lg-38 {
    margin-top: 38px !important; }
  .mt-lg-39 {
    margin-top: 39px !important; }
  .mt-lg-40 {
    margin-top: 40px !important; }
  .mt-lg-45 {
    margin-top: 45px !important; }
  .mt-lg-50 {
    margin-top: 50px !important; }
  .mt-lg-60 {
    margin-top: 60px !important; }
  .mt-lg-65 {
    margin-top: 65px !important; }
  .mt-lg-70 {
    margin-top: 70px !important; }
  .mt-lg-80 {
    margin-top: 80px !important; }
  .mt-lg-90 {
    margin-top: 90px !important; }
  .mt-lg-95 {
    margin-top: 95px !important; }
  .mt-lg-100 {
    margin-top: 100px !important; }
  .mt-lg-110 {
    margin-top: 110px !important; }
  .mt-lg-120 {
    margin-top: 120px !important; }
  .mt-lg-130 {
    margin-top: 130px !important; }
  .mt-lg-140 {
    margin-top: 140px !important; }
  .mt-lg-150 {
    margin-top: 150px !important; }
  .mt-lg-160 {
    margin-top: 160px !important; }
  .mt-lg-170 {
    margin-top: 170px !important; }
  .mt-lg-175 {
    margin-top: 175px !important; }
  .mt-lg-180 {
    margin-top: 180px !important; }
  .mt-lg-190 {
    margin-top: 190px !important; }
  .mt-lg-200 {
    margin-top: 200px !important; }
  .mt-lg-210 {
    margin-top: 210px !important; }
  .mt-lg-250 {
    margin-top: 250px !important; }
  .mt-lg-280 {
    margin-top: 280px !important; } }

@media (min-width: 1230px) {
  .mt-xl--50 {
    margin-top: -50px !important; }
  .mt-xl--30 {
    margin-top: -30px !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-8 {
    margin-top: 8px !important; }
  .mt-xl-10 {
    margin-top: 10px !important; }
  .mt-xl-15 {
    margin-top: 15px !important; }
  .mt-xl-18 {
    margin-top: 18px !important; }
  .mt-xl-20 {
    margin-top: 20px !important; }
  .mt-xl-25 {
    margin-top: 25px !important; }
  .mt-xl-30 {
    margin-top: 30px !important; }
  .mt-xl-31 {
    margin-top: 31px !important; }
  .mt-xl-32 {
    margin-top: 32px !important; }
  .mt-xl-33 {
    margin-top: 33px !important; }
  .mt-xl-34 {
    margin-top: 34px !important; }
  .mt-xl-35 {
    margin-top: 35px !important; }
  .mt-xl-36 {
    margin-top: 36px !important; }
  .mt-xl-37 {
    margin-top: 37px !important; }
  .mt-xl-38 {
    margin-top: 38px !important; }
  .mt-xl-39 {
    margin-top: 39px !important; }
  .mt-xl-40 {
    margin-top: 40px !important; }
  .mt-xl-45 {
    margin-top: 45px !important; }
  .mt-xl-50 {
    margin-top: 50px !important; }
  .mt-xl-60 {
    margin-top: 60px !important; }
  .mt-xl-65 {
    margin-top: 65px !important; }
  .mt-xl-70 {
    margin-top: 70px !important; }
  .mt-xl-80 {
    margin-top: 80px !important; }
  .mt-xl-90 {
    margin-top: 90px !important; }
  .mt-xl-95 {
    margin-top: 95px !important; }
  .mt-xl-100 {
    margin-top: 100px !important; }
  .mt-xl-110 {
    margin-top: 110px !important; }
  .mt-xl-120 {
    margin-top: 120px !important; }
  .mt-xl-130 {
    margin-top: 130px !important; }
  .mt-xl-140 {
    margin-top: 140px !important; }
  .mt-xl-150 {
    margin-top: 150px !important; }
  .mt-xl-160 {
    margin-top: 160px !important; }
  .mt-xl-170 {
    margin-top: 170px !important; }
  .mt-xl-175 {
    margin-top: 175px !important; }
  .mt-xl-180 {
    margin-top: 180px !important; }
  .mt-xl-190 {
    margin-top: 190px !important; }
  .mt-xl-200 {
    margin-top: 200px !important; }
  .mt-xl-210 {
    margin-top: 210px !important; }
  .mt-xl-250 {
    margin-top: 250px !important; }
  .mt-xl-280 {
    margin-top: 280px !important; } }

.mb--50 {
  margin-bottom: -50px !important; }

.mb--30 {
  margin-bottom: -30px !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-18 {
  margin-bottom: 18px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-31 {
  margin-bottom: 31px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.mb-33 {
  margin-bottom: 33px !important; }

.mb-34 {
  margin-bottom: 34px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-36 {
  margin-bottom: 36px !important; }

.mb-37 {
  margin-bottom: 37px !important; }

.mb-38 {
  margin-bottom: 38px !important; }

.mb-39 {
  margin-bottom: 39px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-110 {
  margin-bottom: 110px !important; }

.mb-120 {
  margin-bottom: 120px !important; }

.mb-130 {
  margin-bottom: 130px !important; }

.mb-140 {
  margin-bottom: 140px !important; }

.mb-150 {
  margin-bottom: 150px !important; }

.mb-160 {
  margin-bottom: 160px !important; }

.mb-170 {
  margin-bottom: 170px !important; }

.mb-175 {
  margin-bottom: 175px !important; }

.mb-180 {
  margin-bottom: 180px !important; }

.mb-190 {
  margin-bottom: 190px !important; }

.mb-200 {
  margin-bottom: 200px !important; }

.mb-210 {
  margin-bottom: 210px !important; }

.mb-250 {
  margin-bottom: 250px !important; }

.mb-280 {
  margin-bottom: 280px !important; }

@media (min-width: 1px) {
  .mb-xs--50 {
    margin-bottom: -50px !important; }
  .mb-xs--30 {
    margin-bottom: -30px !important; }
  .mb-xs-0 {
    margin-bottom: 0 !important; }
  .mb-xs-8 {
    margin-bottom: 8px !important; }
  .mb-xs-10 {
    margin-bottom: 10px !important; }
  .mb-xs-15 {
    margin-bottom: 15px !important; }
  .mb-xs-18 {
    margin-bottom: 18px !important; }
  .mb-xs-20 {
    margin-bottom: 20px !important; }
  .mb-xs-25 {
    margin-bottom: 25px !important; }
  .mb-xs-30 {
    margin-bottom: 30px !important; }
  .mb-xs-31 {
    margin-bottom: 31px !important; }
  .mb-xs-32 {
    margin-bottom: 32px !important; }
  .mb-xs-33 {
    margin-bottom: 33px !important; }
  .mb-xs-34 {
    margin-bottom: 34px !important; }
  .mb-xs-35 {
    margin-bottom: 35px !important; }
  .mb-xs-36 {
    margin-bottom: 36px !important; }
  .mb-xs-37 {
    margin-bottom: 37px !important; }
  .mb-xs-38 {
    margin-bottom: 38px !important; }
  .mb-xs-39 {
    margin-bottom: 39px !important; }
  .mb-xs-40 {
    margin-bottom: 40px !important; }
  .mb-xs-45 {
    margin-bottom: 45px !important; }
  .mb-xs-50 {
    margin-bottom: 50px !important; }
  .mb-xs-60 {
    margin-bottom: 60px !important; }
  .mb-xs-65 {
    margin-bottom: 65px !important; }
  .mb-xs-70 {
    margin-bottom: 70px !important; }
  .mb-xs-80 {
    margin-bottom: 80px !important; }
  .mb-xs-90 {
    margin-bottom: 90px !important; }
  .mb-xs-95 {
    margin-bottom: 95px !important; }
  .mb-xs-100 {
    margin-bottom: 100px !important; }
  .mb-xs-110 {
    margin-bottom: 110px !important; }
  .mb-xs-120 {
    margin-bottom: 120px !important; }
  .mb-xs-130 {
    margin-bottom: 130px !important; }
  .mb-xs-140 {
    margin-bottom: 140px !important; }
  .mb-xs-150 {
    margin-bottom: 150px !important; }
  .mb-xs-160 {
    margin-bottom: 160px !important; }
  .mb-xs-170 {
    margin-bottom: 170px !important; }
  .mb-xs-175 {
    margin-bottom: 175px !important; }
  .mb-xs-180 {
    margin-bottom: 180px !important; }
  .mb-xs-190 {
    margin-bottom: 190px !important; }
  .mb-xs-200 {
    margin-bottom: 200px !important; }
  .mb-xs-210 {
    margin-bottom: 210px !important; }
  .mb-xs-250 {
    margin-bottom: 250px !important; }
  .mb-xs-280 {
    margin-bottom: 280px !important; } }

@media (min-width: 576px) {
  .mb-sm--50 {
    margin-bottom: -50px !important; }
  .mb-sm--30 {
    margin-bottom: -30px !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-8 {
    margin-bottom: 8px !important; }
  .mb-sm-10 {
    margin-bottom: 10px !important; }
  .mb-sm-15 {
    margin-bottom: 15px !important; }
  .mb-sm-18 {
    margin-bottom: 18px !important; }
  .mb-sm-20 {
    margin-bottom: 20px !important; }
  .mb-sm-25 {
    margin-bottom: 25px !important; }
  .mb-sm-30 {
    margin-bottom: 30px !important; }
  .mb-sm-31 {
    margin-bottom: 31px !important; }
  .mb-sm-32 {
    margin-bottom: 32px !important; }
  .mb-sm-33 {
    margin-bottom: 33px !important; }
  .mb-sm-34 {
    margin-bottom: 34px !important; }
  .mb-sm-35 {
    margin-bottom: 35px !important; }
  .mb-sm-36 {
    margin-bottom: 36px !important; }
  .mb-sm-37 {
    margin-bottom: 37px !important; }
  .mb-sm-38 {
    margin-bottom: 38px !important; }
  .mb-sm-39 {
    margin-bottom: 39px !important; }
  .mb-sm-40 {
    margin-bottom: 40px !important; }
  .mb-sm-45 {
    margin-bottom: 45px !important; }
  .mb-sm-50 {
    margin-bottom: 50px !important; }
  .mb-sm-60 {
    margin-bottom: 60px !important; }
  .mb-sm-65 {
    margin-bottom: 65px !important; }
  .mb-sm-70 {
    margin-bottom: 70px !important; }
  .mb-sm-80 {
    margin-bottom: 80px !important; }
  .mb-sm-90 {
    margin-bottom: 90px !important; }
  .mb-sm-95 {
    margin-bottom: 95px !important; }
  .mb-sm-100 {
    margin-bottom: 100px !important; }
  .mb-sm-110 {
    margin-bottom: 110px !important; }
  .mb-sm-120 {
    margin-bottom: 120px !important; }
  .mb-sm-130 {
    margin-bottom: 130px !important; }
  .mb-sm-140 {
    margin-bottom: 140px !important; }
  .mb-sm-150 {
    margin-bottom: 150px !important; }
  .mb-sm-160 {
    margin-bottom: 160px !important; }
  .mb-sm-170 {
    margin-bottom: 170px !important; }
  .mb-sm-175 {
    margin-bottom: 175px !important; }
  .mb-sm-180 {
    margin-bottom: 180px !important; }
  .mb-sm-190 {
    margin-bottom: 190px !important; }
  .mb-sm-200 {
    margin-bottom: 200px !important; }
  .mb-sm-210 {
    margin-bottom: 210px !important; }
  .mb-sm-250 {
    margin-bottom: 250px !important; }
  .mb-sm-280 {
    margin-bottom: 280px !important; } }

@media (min-width: 768px) {
  .mb-md--50 {
    margin-bottom: -50px !important; }
  .mb-md--30 {
    margin-bottom: -30px !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-8 {
    margin-bottom: 8px !important; }
  .mb-md-10 {
    margin-bottom: 10px !important; }
  .mb-md-15 {
    margin-bottom: 15px !important; }
  .mb-md-18 {
    margin-bottom: 18px !important; }
  .mb-md-20 {
    margin-bottom: 20px !important; }
  .mb-md-25 {
    margin-bottom: 25px !important; }
  .mb-md-30 {
    margin-bottom: 30px !important; }
  .mb-md-31 {
    margin-bottom: 31px !important; }
  .mb-md-32 {
    margin-bottom: 32px !important; }
  .mb-md-33 {
    margin-bottom: 33px !important; }
  .mb-md-34 {
    margin-bottom: 34px !important; }
  .mb-md-35 {
    margin-bottom: 35px !important; }
  .mb-md-36 {
    margin-bottom: 36px !important; }
  .mb-md-37 {
    margin-bottom: 37px !important; }
  .mb-md-38 {
    margin-bottom: 38px !important; }
  .mb-md-39 {
    margin-bottom: 39px !important; }
  .mb-md-40 {
    margin-bottom: 40px !important; }
  .mb-md-45 {
    margin-bottom: 45px !important; }
  .mb-md-50 {
    margin-bottom: 50px !important; }
  .mb-md-60 {
    margin-bottom: 60px !important; }
  .mb-md-65 {
    margin-bottom: 65px !important; }
  .mb-md-70 {
    margin-bottom: 70px !important; }
  .mb-md-80 {
    margin-bottom: 80px !important; }
  .mb-md-90 {
    margin-bottom: 90px !important; }
  .mb-md-95 {
    margin-bottom: 95px !important; }
  .mb-md-100 {
    margin-bottom: 100px !important; }
  .mb-md-110 {
    margin-bottom: 110px !important; }
  .mb-md-120 {
    margin-bottom: 120px !important; }
  .mb-md-130 {
    margin-bottom: 130px !important; }
  .mb-md-140 {
    margin-bottom: 140px !important; }
  .mb-md-150 {
    margin-bottom: 150px !important; }
  .mb-md-160 {
    margin-bottom: 160px !important; }
  .mb-md-170 {
    margin-bottom: 170px !important; }
  .mb-md-175 {
    margin-bottom: 175px !important; }
  .mb-md-180 {
    margin-bottom: 180px !important; }
  .mb-md-190 {
    margin-bottom: 190px !important; }
  .mb-md-200 {
    margin-bottom: 200px !important; }
  .mb-md-210 {
    margin-bottom: 210px !important; }
  .mb-md-250 {
    margin-bottom: 250px !important; }
  .mb-md-280 {
    margin-bottom: 280px !important; } }

@media (min-width: 992px) {
  .mb-lg--50 {
    margin-bottom: -50px !important; }
  .mb-lg--30 {
    margin-bottom: -30px !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-8 {
    margin-bottom: 8px !important; }
  .mb-lg-10 {
    margin-bottom: 10px !important; }
  .mb-lg-15 {
    margin-bottom: 15px !important; }
  .mb-lg-18 {
    margin-bottom: 18px !important; }
  .mb-lg-20 {
    margin-bottom: 20px !important; }
  .mb-lg-25 {
    margin-bottom: 25px !important; }
  .mb-lg-30 {
    margin-bottom: 30px !important; }
  .mb-lg-31 {
    margin-bottom: 31px !important; }
  .mb-lg-32 {
    margin-bottom: 32px !important; }
  .mb-lg-33 {
    margin-bottom: 33px !important; }
  .mb-lg-34 {
    margin-bottom: 34px !important; }
  .mb-lg-35 {
    margin-bottom: 35px !important; }
  .mb-lg-36 {
    margin-bottom: 36px !important; }
  .mb-lg-37 {
    margin-bottom: 37px !important; }
  .mb-lg-38 {
    margin-bottom: 38px !important; }
  .mb-lg-39 {
    margin-bottom: 39px !important; }
  .mb-lg-40 {
    margin-bottom: 40px !important; }
  .mb-lg-45 {
    margin-bottom: 45px !important; }
  .mb-lg-50 {
    margin-bottom: 50px !important; }
  .mb-lg-60 {
    margin-bottom: 60px !important; }
  .mb-lg-65 {
    margin-bottom: 65px !important; }
  .mb-lg-70 {
    margin-bottom: 70px !important; }
  .mb-lg-80 {
    margin-bottom: 80px !important; }
  .mb-lg-90 {
    margin-bottom: 90px !important; }
  .mb-lg-95 {
    margin-bottom: 95px !important; }
  .mb-lg-100 {
    margin-bottom: 100px !important; }
  .mb-lg-110 {
    margin-bottom: 110px !important; }
  .mb-lg-120 {
    margin-bottom: 120px !important; }
  .mb-lg-130 {
    margin-bottom: 130px !important; }
  .mb-lg-140 {
    margin-bottom: 140px !important; }
  .mb-lg-150 {
    margin-bottom: 150px !important; }
  .mb-lg-160 {
    margin-bottom: 160px !important; }
  .mb-lg-170 {
    margin-bottom: 170px !important; }
  .mb-lg-175 {
    margin-bottom: 175px !important; }
  .mb-lg-180 {
    margin-bottom: 180px !important; }
  .mb-lg-190 {
    margin-bottom: 190px !important; }
  .mb-lg-200 {
    margin-bottom: 200px !important; }
  .mb-lg-210 {
    margin-bottom: 210px !important; }
  .mb-lg-250 {
    margin-bottom: 250px !important; }
  .mb-lg-280 {
    margin-bottom: 280px !important; } }

@media (min-width: 1230px) {
  .mb-xl--50 {
    margin-bottom: -50px !important; }
  .mb-xl--30 {
    margin-bottom: -30px !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-8 {
    margin-bottom: 8px !important; }
  .mb-xl-10 {
    margin-bottom: 10px !important; }
  .mb-xl-15 {
    margin-bottom: 15px !important; }
  .mb-xl-18 {
    margin-bottom: 18px !important; }
  .mb-xl-20 {
    margin-bottom: 20px !important; }
  .mb-xl-25 {
    margin-bottom: 25px !important; }
  .mb-xl-30 {
    margin-bottom: 30px !important; }
  .mb-xl-31 {
    margin-bottom: 31px !important; }
  .mb-xl-32 {
    margin-bottom: 32px !important; }
  .mb-xl-33 {
    margin-bottom: 33px !important; }
  .mb-xl-34 {
    margin-bottom: 34px !important; }
  .mb-xl-35 {
    margin-bottom: 35px !important; }
  .mb-xl-36 {
    margin-bottom: 36px !important; }
  .mb-xl-37 {
    margin-bottom: 37px !important; }
  .mb-xl-38 {
    margin-bottom: 38px !important; }
  .mb-xl-39 {
    margin-bottom: 39px !important; }
  .mb-xl-40 {
    margin-bottom: 40px !important; }
  .mb-xl-45 {
    margin-bottom: 45px !important; }
  .mb-xl-50 {
    margin-bottom: 50px !important; }
  .mb-xl-60 {
    margin-bottom: 60px !important; }
  .mb-xl-65 {
    margin-bottom: 65px !important; }
  .mb-xl-70 {
    margin-bottom: 70px !important; }
  .mb-xl-80 {
    margin-bottom: 80px !important; }
  .mb-xl-90 {
    margin-bottom: 90px !important; }
  .mb-xl-95 {
    margin-bottom: 95px !important; }
  .mb-xl-100 {
    margin-bottom: 100px !important; }
  .mb-xl-110 {
    margin-bottom: 110px !important; }
  .mb-xl-120 {
    margin-bottom: 120px !important; }
  .mb-xl-130 {
    margin-bottom: 130px !important; }
  .mb-xl-140 {
    margin-bottom: 140px !important; }
  .mb-xl-150 {
    margin-bottom: 150px !important; }
  .mb-xl-160 {
    margin-bottom: 160px !important; }
  .mb-xl-170 {
    margin-bottom: 170px !important; }
  .mb-xl-175 {
    margin-bottom: 175px !important; }
  .mb-xl-180 {
    margin-bottom: 180px !important; }
  .mb-xl-190 {
    margin-bottom: 190px !important; }
  .mb-xl-200 {
    margin-bottom: 200px !important; }
  .mb-xl-210 {
    margin-bottom: 210px !important; }
  .mb-xl-250 {
    margin-bottom: 250px !important; }
  .mb-xl-280 {
    margin-bottom: 280px !important; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*===> Begin team-card <===*/
.team-card {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto; }
  .team-card .engineer-card {
    margin-bottom: 0; }
  .team-card .desription {
    position: relative;
    padding: 40px 30px 36px;
    background: var(--color-gray); }
    .team-card .desription::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: var(--color-primary);
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .team-card .desription p {
      margin-bottom: 0;
      color: var(--color-text-gray); }
  @media (max-width: 991px) {
    .team-card .desription {
      padding: 35px 25px 31px; } }
  @media (max-width: 767px) {
    .team-card .desription {
      padding: 30px 20px 26px; } }
  @media (max-width: 480px) {
    .team-card .desription {
      padding: 25px 15px 21px; } }

.members {
  margin-left: -15px;
  margin-right: -15px; }
  .members .tt-slide {
    padding-left: 15px;
    padding-right: 15px; }

/*===> End team-card <===*/

/*===> Begin engineer-card <===*/
.engineer-card {
  position: relative;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 55px 30px;
  background: #fff;
  box-shadow: 0px 5px 20px rgba(52, 51, 55, 0.1); }
  .engineer-card .engineer-card_info {
    position: relative;
    z-index: 2; }
  .engineer-card .engineer-avatar {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    max-height: calc(100% - 7px);
    mix-blend-mode: luminosity; }
  @media (max-width: 991px) {
    .engineer-card {
      padding: 45px 25px; } }
  @media (max-width: 767px) {
    .engineer-card {
      padding: 40px 20px; }
      .engineer-card .engineer-card_info p {
        margin-bottom: 25px; } }
  @media (max-width: 480px) {
    .engineer-card {
      padding: 30px 20px; } }

.engineer-slider {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px; }
  .engineer-slider.slick-initialized {
    margin-bottom: 0; }
    .engineer-slider.slick-initialized .tt-slide {
      margin-bottom: -30px; }
  .engineer-slider .tt-slide {
    width: 33.33%;
    padding-left: 15px;
    padding-right: 15px; }

/*===> End engineer-card <===*/

/*===> Begin section-header <===*/
section .section-content > .block-editable:first-child {
  max-width: 575px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 50px; }
  section.dark .section-content > .block-editable:first-child h2 {
    color: #fff; }
  section.dark .section-content > .block-editable:first-child p {
    color: var(--color-text-gray); }
  section .section-content > .block-editable:first-child h2 {
    margin-bottom: 15px;
    text-transform: capitalize; }
  section .section-content > .block-editable:first-child p {
    margin-bottom: 0;
    font-size: 19px;
    color: var(--color-header-secondary); }
  @media (max-width: 991px) {
    section .section-content > .block-editable:first-child {
      margin-bottom: 40px; }
      section > .section-content > .block-editable:first-child p {
        font-size: 16px; } }
  @media (max-width: 767px) {
    section .section-content > .block-editable:first-child {
      margin-bottom: 30px; }
      section .section-content > .block-editable:first-child p {
        font-size: 15px; }
      section .section-content > .block-editable:first-child .fw-700 {
        font-weight: 400 !important; } }

/*===> End section-header <===*/

/*===> Begin parallax-section <===*/
section.parallax {
  position: relative; }
  section.parallax > * {
    position: relative;
    z-index: 10; }
  section.parallax .parallax-img {
    position: absolute;
    top: 0;
    left: -150px;
    z-index: 0; }
    section.parallax .parallax-img.animate {
      animation: parallax-img 3.3s infinite ease-in-out; }
    section.parallax .parallax-img.parallax-right {
      left: auto;
      right: 0; }
    section.parallax .parallax-img.parallax-v-center {
      top: 20%; }
    section.parallax .parallax-img.parallax-bottom {
      top: 50%; }
    section.parallax .parallax-img.anim-delay {
      animation-delay: 1.65s; }

.block-bg-projects {
  /* background: url("../img/paralax-2.jpg") no-repeat; */
  width: 100%;
  height: 100%;
  background-size: cover; }

/*===> End parallax-section <===*/

/*===> Begin v-separator <===*/
.v-separator {
  position: relative; }
  .v-separator::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 10;
    -ms-transform: translate(-50%, 50%);
        transform: translate(-50%, 50%);
    width: 4px;
    height: 63px;
    border-radius: 4px;
    background: linear-gradient(to top, var(--color-primary) 0%, var(--color-secondary) 100%); }
  @media (max-width: 991px) {
    .v-separator::after {
      height: 46px; } }
  @media (max-width: 480px) {
    .v-separator::after {
      height: 30px; } }
  @media (max-width: 575px) {
    .v-separator::after {
      display: none; } }

/*===> End v-separator <===*/

/*===> Begin studio-slider <===*/
section.studio .carousel .slide-container {
  position: relative; }
  section.studio .carousel .slide-container .slide-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-top: 15px; }
    section.studio .carousel .slide-container .slide-content::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, #1C1B1F -7.12%, rgba(28, 27, 31, 0) 71.16%); }
    section.studio .carousel .slide-container .slide-content > * {
      position: relative;
      z-index: 10; }
    section.studio .carousel .slide-container .slide-content h4 {
      margin-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
      color: #fff; }
    section.studio .carousel .slide-container .slide-content .block-editable {
      padding: 19px 40px;
      background: rgba(var(--color-dark-rgb), 0.9); }
      section.studio .carousel .slide-container .slide-content .block-editable p {
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        color: var(--color-text-gray); }
  section.studio .carousel .tt-slide.feature-background .slide-container > img {
    position: relative;
    z-index: 2; }

section.studio .carousel .tt-slide {
  overflow: hidden;
  opacity: .5;
  transition: opacity .3s; }
  section.studio .carousel .tt-slide.animate {
    opacity: 1; }
    section.studio .carousel .tt-slide.animate .block-editable {
      -ms-transform: translateY(0);
          transform: translateY(0); }
  section.studio .carousel .tt-slide .block-editable {
    transition: all .4s;
    -ms-transform: translateY(100%);
        transform: translateY(100%); }

@media (max-width: 767px) {
  section.studio .carousel .slide-container {
    min-height: 375px;
    height: 375px; }
    section.studio .carousel .slide-container .slide-content .block-editable {
      padding: 15px 15px; }
    section.studio .carousel .tt-slide.feature-background .slide-container > img {
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
      max-width: none;
      max-height: 100%; } }

/*===> End studio-slider <===*/

/*===> Begin start video btn <===*/
.start-video-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translateX(-100%) translateY(-50%) translateZ(80px);
  transition: all .3s; }
  .start-video-wrap:hover::before, .start-video-wrap:hover::after {
    background: var(--color-secondary); }
  .start-video-wrap:hover .start-video {
    background: var(--color-secondary); }
  .start-video-wrap::before, .start-video-wrap::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--color-primary);
    opacity: .5;
    animation: pulse 1.3s infinite linear;
    -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    transition: all .3s; }
  .start-video-wrap::after {
    display: none;
    animation-delay: .4s; }
  .start-video-wrap .start-video {
    position: relative;
    z-index: 10;
    width: 94px;
    height: 93px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    border-radius: 50%;
    background: var(--color-primary);
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.4);
    transition: all .3s; }
  .start-video-wrap svg {
    margin-left: 6px; }
  .start-video-wrap path {
    transition: all .3s;
    fill: #fff; }
  @media (max-width: 991px) {
    .start-video-wrap .start-video {
      width: 73px;
      height: 73px; } }

/*===> End start video btn <===*/

/*===> Begin gallery-grid <===*/
.gallery-grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .gallery-grid .gallery-grid__item {
    position: relative;
    width: 20%; }
    .gallery-grid .gallery-grid__item:hover .grid-item-content {
      opacity: 1;
      visibility: visible; }
    .gallery-grid .gallery-grid__item:hover .icon {
      opacity: 0; }
    .gallery-grid .gallery-grid__item .start-video-wrap {
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .gallery-grid .gallery-grid__item .icon {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 10px;
      background: rgba(var(--color-dark-rgb), 0.9);
      transition: all .4s;
      font-size: 21px;
      color:  var(--color-primary); }
  .gallery-grid .grid-item-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    padding: 15px;
    background: rgba(39, 38, 43, 0.9);
    text-align: center;
    transition: all .4s;
    visibility: hidden;
    opacity: 0; }
    .gallery-grid .grid-item-content .grid-item-ico {
      margin-bottom: 10px; }
    .gallery-grid .grid-item-content .grid-item-title {
      margin-bottom: 10px;
      font-family: var(--font-header);
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1em;
      letter-spacing: -0.03em;
      color: #fff; }
    .gallery-grid .grid-item-content .grid-item-info {
      font-weight: 700;
      font-size: 13px;
      color: #fff; }
      .gallery-grid .grid-item-content .grid-item-info span {
        padding: 0 10px; }
  @media (max-width: 991px) {
    .gallery-grid .grid-item-content .grid-item-title {
      font-size: 14px; } }
  @media (max-width: 767px) {
    .gallery-grid .gallery-grid__item {
      width: 33.33%; }
    .gallery-grid .grid-item-content .grid-item-title {
      font-size: 12px; } }
  @media (max-width: 480px) {
    .gallery-grid .gallery-grid__item {
      width: 50%; } }

/*===> End gallery-grid <===*/
/*=-==> Begin masonry-grid <===*/
.masonry-grid > a {
  transition: all 0s; }

/*=-==> End masonry-grid <===*/


/*===> Begin client-filter <===*/
.client-filter,
.masonry-filter {
  position: relative; }
  .client-filter .client-filter-list,
  .client-filter .masonry-filter-list,
  .masonry-filter .client-filter-list,
  .masonry-filter .masonry-filter-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100%; }
    .client-filter .client-filter-list.white .client-filter-list__item::before,
    .client-filter .client-filter-list.white .masonry-filter-list__item::before,
    .client-filter .masonry-filter-list.white .client-filter-list__item::before,
    .client-filter .masonry-filter-list.white .masonry-filter-list__item::before,
    .masonry-filter .client-filter-list.white .client-filter-list__item::before,
    .masonry-filter .client-filter-list.white .masonry-filter-list__item::before,
    .masonry-filter .masonry-filter-list.white .client-filter-list__item::before,
    .masonry-filter .masonry-filter-list.white .masonry-filter-list__item::before {
      border-top: 1px solid #424048;
      opacity: 1; }
    .client-filter .client-filter-list.white .client-filter-list__item a,
    .client-filter .client-filter-list.white .masonry-filter-list__item a,
    .client-filter .masonry-filter-list.white .client-filter-list__item a,
    .client-filter .masonry-filter-list.white .masonry-filter-list__item a,
    .masonry-filter .client-filter-list.white .client-filter-list__item a,
    .masonry-filter .client-filter-list.white .masonry-filter-list__item a,
    .masonry-filter .masonry-filter-list.white .client-filter-list__item a,
    .masonry-filter .masonry-filter-list.white .masonry-filter-list__item a {
      color: #fff; }
  .client-filter li,
  .masonry-filter li {
    padding-left: 0;
    margin-bottom: 25px; }
    .client-filter li::after,
    .masonry-filter li::after {
      display: none; }
  .client-filter .client-filter-list__item,
  .client-filter .masonry-filter-list__item,
  .masonry-filter .client-filter-list__item,
  .masonry-filter .masonry-filter-list__item {
    position: relative;
    margin-right: 90px; }
    .client-filter .client-filter-list__item::before,
    .client-filter .masonry-filter-list__item::before,
    .masonry-filter .client-filter-list__item::before,
    .masonry-filter .masonry-filter-list__item::before {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(100% + 10px);
      width: 70px;
      -ms-transform: translateY(-11px);
      transform: translateY(-11px);
      border-top: 1px solid transparent;
      opacity: .2; }
    .client-filter .client-filter-list__item:last-child,
    .client-filter .masonry-filter-list__item:last-child,
    .masonry-filter .client-filter-list__item:last-child,
    .masonry-filter .masonry-filter-list__item:last-child {
      margin-right: 0;
      margin-bottom: 25px; }
      .client-filter .client-filter-list__item:last-child::before,
      .client-filter .masonry-filter-list__item:last-child::before,
      .masonry-filter .client-filter-list__item:last-child::before,
      .masonry-filter .masonry-filter-list__item:last-child::before {
        display: none; }
    .client-filter .client-filter-list__item a,
    .client-filter .masonry-filter-list__item a,
    .masonry-filter .client-filter-list__item a,
    .masonry-filter .masonry-filter-list__item a {
      position: relative;
      padding-bottom: 25px;
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 1em;
      text-align: center;
      color: var(--color-header-primary); }
      .client-filter .client-filter-list__item a:hover,
      .client-filter .masonry-filter-list__item a:hover,
      .masonry-filter .client-filter-list__item a:hover,
      .masonry-filter .masonry-filter-list__item a:hover {
        color: var(--color-primary); }
      .client-filter .client-filter-list__item a::before,
      .client-filter .masonry-filter-list__item a::before,
      .masonry-filter .client-filter-list__item a::before,
      .masonry-filter .masonry-filter-list__item a::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0%;
        height: 4px;
        border-radius: 4px;
        transition: all .3s;
        background: var(--color-primary);
        /* Old browsers */
        /* FF3.6-15 */
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(-45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
        /* IE6-9 fallback on horizontal gradient */ }
      .client-filter .client-filter-list__item a.active::before,
      .client-filter .masonry-filter-list__item a.active::before,
      .masonry-filter .client-filter-list__item a.active::before,
      .masonry-filter .masonry-filter-list__item a.active::before {
        left: 0;
        width: 100%; }
  @media (max-width: 1199px) {
    .client-filter .client-filter-list__item,
    .client-filter .masonry-filter-list__item,
    .masonry-filter .client-filter-list__item,
    .masonry-filter .masonry-filter-list__item {
      margin-right: 45px; }
      .client-filter .client-filter-list__item::before,
      .client-filter .masonry-filter-list__item::before,
      .masonry-filter .client-filter-list__item::before,
      .masonry-filter .masonry-filter-list__item::before {
        left: calc(100% + 5px);
        width: 35px; } }
  @media (max-width: 991px) {
    .client-filter .client-filter-list__item,
    .client-filter .masonry-filter-list__item,
    .masonry-filter .client-filter-list__item,
    .masonry-filter .masonry-filter-list__item {
      margin-right: 30px; }
      .client-filter .client-filter-list__item::before,
      .client-filter .masonry-filter-list__item::before,
      .masonry-filter .client-filter-list__item::before,
      .masonry-filter .masonry-filter-list__item::before {
        left: calc(100% + 5px);
        width: 20px; }
      .client-filter .client-filter-list__item a,
      .client-filter .masonry-filter-list__item a,
      .masonry-filter .client-filter-list__item a,
      .masonry-filter .masonry-filter-list__item a {
        font-size: 16px; } }
  @media (max-width: 767px) {
    .client-filter .client-filter-list,
    .client-filter .masonry-filter-list,
    .masonry-filter .client-filter-list,
    .masonry-filter .masonry-filter-list {
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center; }
    .client-filter .client-filter-list__item,
    .client-filter .masonry-filter-list__item,
    .masonry-filter .client-filter-list__item,
    .masonry-filter .masonry-filter-list__item {
      margin-right: 0;
      margin-bottom: 15px; }
      .client-filter .client-filter-list__item::before,
      .client-filter .masonry-filter-list__item::before,
      .masonry-filter .client-filter-list__item::before,
      .masonry-filter .masonry-filter-list__item::before {
        display: none; }
      .client-filter .client-filter-list__item a,
      .client-filter .masonry-filter-list__item a,
      .masonry-filter .client-filter-list__item a,
      .masonry-filter .masonry-filter-list__item a {
        padding-bottom: 10px; } }

/*===> End client-filter <===*/

/*===> Begin popup-wrap <===*/
.popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  transition: all .3s;
  visibility: hidden;
  opacity: 0; }
  .popup-wrap .popup-inside {
    z-index: 10000;
    -ms-transform: scale(0.5);
        transform: scale(0.5);
    margin-bottom: 0;
    margin-top: 0;
    opacity: 0;
    transition: all .3s; }
  .popup-wrap.show {
    opacity: 1;
    visibility: visible; }
    .popup-wrap.show .popup-inside {
      -ms-transform: scale(1);
          transform: scale(1);
      opacity: 1; }

/*===> End popup-wrap <===*/

/*===> Begin popup-content <===*/
.popup-content {
  position: relative;
  max-width: 980px;
  width: 100%;
  margin: 120px auto;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 50px;
  padding-bottom: 20px;
  background: #fff; }
  .popup-content::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    background: var(--color-primary);
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stop',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
  .popup-content h3 {
    margin-bottom: 20px; }
  .popup-content .close {
    float: right;
    position: relative;
    -ms-transform: translate(100%, -50%);
        transform: translate(100%, -50%); }
  @media (max-width: 991px) {
    .popup-content .close {
      -ms-transform: translate(-10%, -50%);
          transform: translate(-10%, -50%); } }
  @media (max-width: 767px) {
    .popup-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px; } }
  @media (max-width: 480px) {
    .popup-content {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px; } }

/*===> End popup-content <===*/
/*===> Begin input <===*/
input {
  display: block;
  width: 100%;
  min-width: 0;
  margin-bottom: 10px;
  padding: 11px 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-size: 16px;
  line-height: 1em;
  color: var(--color-text);
  transition: all .3s; }
  input:focus {
    border-color: var(--color-primary); }

input[type="checkbox"] {
  margin-bottom: 0;
  margin-top: .4em;
  padding: 0;
}

.date-input {
  position: relative;
  display: block; }
  .date-input input {
    padding-right: 40px; }
  .date-input .input-ico {
    position: absolute;
    top: 50%;
    right: 10px;
    -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    color: var(--color-primary); }
    .date-input .input-ico path {
      fill: var(--color-primary); }

textarea {
  display: block;
  width: 100%;
  min-width: 0;
  min-height: 90px;
  margin-bottom: 10px;
  padding: 11px 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-size: 16px;
  line-height: 1em;
  color: var(--color-text);
  transition: all .3s;
  resize: none; }
  textarea:focus {
    border-color: var(--color-primary); }

/*===> End input <===*/
/*===> Begin book-form <===*/
.book-form .tt-form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }

.book-form .tt-form-control_30 {
  display: block;
  width: 30%;
  padding: 0 10px; }

.book-form .tt-form-control_33 {
  display: block;
  width: 33.33%;
  padding: 0 10px; }

.book-form .tt-form-control_40 {
  display: block;
  width: 40%;
  padding: 0 10px; }

.book-form .tt-form-control_50 {
  display: block;
  width: 50%;
  padding: 0 10px; }

.book-form .tt-form-control_60 {
  display: block;
  width: 60%;
  padding: 0 10px; }

.book-form .tt-form-control_66 {
  display: block;
  width: 66.66%;
  padding: 0 10px; }

.book-form .tt-form-control_70 {
  display: block;
  width: 70%;
  padding: 0 10px; }

.book-form .tt-form-control_100 {
  display: block;
  width: 100%;
  padding: 0 10px; }

.book-form button {
  display: block;
  margin-top: 20px;
  min-width: 236px;
  width: 236px;
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 991px) {
  .book-form .tt-form-control_30,
  .book-form .tt-form-control_33,
  .book-form .tt-form-control_40,
  .book-form .tt-form-control_50,
  .book-form .tt-form-control_60,
  .book-form .tt-form-control_66,
  .book-form .tt-form-control_70 {
    width: 100%; } }

/*===> End book-form <===*/
/*===> Begin  form-control <===*/
.form-control {
  height: auto;
  margin-bottom: 10px;
  padding: 12px 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 1em;
  color: var(--color-text); }
  .form-control:focus {
    border-color: var(--color-primary);
    box-shadow: none; }

label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--header_color); }

/*===> End form-control <===*/
/*===> Begin select2 <===*/
select.form-control:not([size]):not([multiple]) {
  height: 43px; }

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.selectWrapper {
  position: relative;
  display: block; }
  .selectWrapper::after {
    content: '\25BE';
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 50%;
    margin-top: -14px;
    pointer-events: none; }

/*===> End select2 <===*/

/*===> Begin datetimepicker <===*/

.bootstrap-datetimepicker-widget .list-unstyled, .datepicker .list-unstyled {
  margin-bottom: 0; }
  .bootstrap-datetimepicker-widget .list-unstyled li, .datepicker .list-unstyled li {
    padding-left: 0; }
    .bootstrap-datetimepicker-widget .list-unstyled li::after, .datepicker .list-unstyled li::after {
      display: none; }
    .bootstrap-datetimepicker-widget .list-unstyled li:nth-child(1), .datepicker .list-unstyled li:nth-child(1) {
      margin-bottom: 0; }
/*
.bootstrap-datetimepicker-widget thead tr, .datepicker thead tr {
  display: -ms-flexbox;
  display: flex;
  border-radius: 0px; }
  .bootstrap-datetimepicker-widget thead tr th, .datepicker thead tr th {
    -ms-flex-positive: 1;
        flex-grow: 1;
    border-radius: 0; }
*/
  .bootstrap-datetimepicker-widget thead tr th, .datepicker thead tr th {
    padding: .5em; }
    .bootstrap-datetimepicker-widget thead tr th:hover, .datepicker thead tr th:hover {
      background: var(--color-secondary) !important;
      color: #fff; }
/*
.bootstrap-datetimepicker-widget table tr, .datepicker table tr {
  display: table;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px; }
  .bootstrap-datetimepicker-widget table tr:nth-child(2), .datepicker table tr:nth-child(2) {
    padding-left: 0;
    padding-right: 0; }
    .bootstrap-datetimepicker-widget table tr:nth-child(2) th, .datepicker table tr:nth-child(2) th {
      margin: 2px;
      text-align: center; }

.bootstrap-datetimepicker-widget table td, .datepicker table td {
  border-radius: 0;
  padding: 5px 2px;
  height: auto; }

.bootstrap-datetimepicker-widget table tbody tr,
.bootstrap-datetimepicker-widget .datepicker-months tbody tr,
.bootstrap-datetimepicker-widget .datepicker-years tbody tr,
.bootstrap-datetimepicker-widget .datepicker-decades tbody tr, .datepicker table tbody tr,
.datepicker .datepicker-months tbody tr,
.datepicker .datepicker-years tbody tr,
.datepicker .datepicker-decades tbody tr {
  background: #fff;
  padding-left: 0;
  padding-right: 0; }
  .bootstrap-datetimepicker-widget table tbody tr span, .bootstrap-datetimepicker-widget table tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-months tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-months tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-years tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-years tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-decades tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-decades tbody tr td, .datepicker table tbody tr span, .datepicker table tbody tr td,
  .datepicker .datepicker-months tbody tr span,
  .datepicker .datepicker-months tbody tr td,
  .datepicker .datepicker-years tbody tr span,
  .datepicker .datepicker-years tbody tr td,
  .datepicker .datepicker-decades tbody tr span,
  .datepicker .datepicker-decades tbody tr td {
    text-align: center; }
    .bootstrap-datetimepicker-widget table tbody tr span.active, .bootstrap-datetimepicker-widget table tbody tr td.active,
    .bootstrap-datetimepicker-widget .datepicker-months tbody tr span.active,
    .bootstrap-datetimepicker-widget .datepicker-months tbody tr td.active,
    .bootstrap-datetimepicker-widget .datepicker-years tbody tr span.active,
    .bootstrap-datetimepicker-widget .datepicker-years tbody tr td.active,
    .bootstrap-datetimepicker-widget .datepicker-decades tbody tr span.active,
    .bootstrap-datetimepicker-widget .datepicker-decades tbody tr td.active, .datepicker table tbody tr span.active, .datepicker table tbody tr td.active,
    .datepicker .datepicker-months tbody tr span.active,
    .datepicker .datepicker-months tbody tr td.active,
    .datepicker .datepicker-years tbody tr span.active,
    .datepicker .datepicker-years tbody tr td.active,
    .datepicker .datepicker-decades tbody tr span.active,
    .datepicker .datepicker-decades tbody tr td.active {
      background: var(--color-primary); }
  .bootstrap-datetimepicker-widget table tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-months tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-years tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-decades tbody tr td, .datepicker table tbody tr td,
  .datepicker .datepicker-months tbody tr td,
  .datepicker .datepicker-years tbody tr td,
  .datepicker .datepicker-decades tbody tr td {
    text-align: left; }
  .bootstrap-datetimepicker-widget table tbody tr span:not([class]),
  .bootstrap-datetimepicker-widget .datepicker-months tbody tr span:not([class]),
  .bootstrap-datetimepicker-widget .datepicker-years tbody tr span:not([class]),
  .bootstrap-datetimepicker-widget .datepicker-decades tbody tr span:not([class]), .datepicker table tbody tr span:not([class]),
  .datepicker .datepicker-months tbody tr span:not([class]),
  .datepicker .datepicker-years tbody tr span:not([class]),
  .datepicker .datepicker-decades tbody tr span:not([class]) {
    display: none; }

.bootstrap-datetimepicker-widget .datepicker-decades table tbody td:first-child *, .datepicker .datepicker-decades table tbody td:first-child * {
  font-size: 16px;
  text-align: left; }

.bootstrap-datetimepicker-widget .datepicker-days tbody tr, .datepicker .datepicker-days tbody tr {
  margin-bottom: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 2px;
  padding-right: 0;
  background: #fff; }
  .bootstrap-datetimepicker-widget .datepicker-days tbody tr td, .datepicker .datepicker-days tbody tr td {
    margin: 2px;
    text-align: center; }
*/
.bootstrap-datetimepicker-widget .datepicker-days, .datepicker .datepicker-days {
  table-layout: fixed;
  min-width:  270px; }
  .bootstrap-datetimepicker-widget .datepicker-days tbody tr td, .datepicker .datepicker-days tbody tr td {
    min-width: 30px;
    padding: .5em; }
    .bootstrap-datetimepicker-widget .datepicker-days tbody tr td.active, .datepicker .datepicker-days tbody tr td.active {
      background: var(--color-primary); }
/*
@media (max-width: 767px) {
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 100%;
    max-width: 260px; } }
*/
.bootstrap-datetimepicker-widget .fa-calendar-alt::after {
  content: ' Today';
  font-family: var(--font-main);
  font-weight: 400; }
.bootstrap-datetimepicker-widget .fa-times::after {
  content: ' Clear';
  font-family: var(--font-main);
  font-weight: 400; }
/*===> End datetimepicker <===*/

/*===> Begin banner-booking <===*/
.banner-booking {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 47px 95px 42px;
  background: var(--color-gray); }
  .banner-booking .banner-text {
    max-width: 580px;
    padding: 0 15px;
    text-align: right; }
    .banner-booking .banner-text h2 {
      color: #fff; }
    .banner-booking .banner-text p {
      margin-bottom: 0;
      font-size: 19px;
      font-weight: bold;
      color: var(--color-text-gray); }
  .banner-booking .banner-button {
    padding: 0 15px;
    text-align: center; }
    .banner-booking .banner-button .button {
      position: relative;
      z-index: 1;
      margin-bottom: 13px;
      min-width: 284px; }
    .banner-booking .banner-button p {
      margin-bottom: 0;
      color: var(--color-text-gray); }
  @media (max-width: 1199px) {
    .banner-booking {
      padding: 40px 60px; } }
  @media (max-width: 991px) {
    .banner-booking {
      -ms-flex-direction: column;
          flex-direction: column;
      padding: 30px 30px; }
      .banner-booking .banner-text {
        max-width: none;
        margin-bottom: 20px;
        padding: 0;
        text-align: center; }
        .banner-booking .banner-text p {
          font-size: 16px; }
      .banner-booking .banner-button .button {
        min-width: 200px;
        width: 200px; } }
  @media (max-width: 767px) {
    .banner-booking {
      padding: 20px 15px; }
      .banner-booking .banner-text p {
        font-size: 15px; } }

#booking-form-target {
  overflow: hidden;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
/*===> End banner-booking <===*/

/*===> Begin review-block <===*/
.review-block {
  margin-bottom: 40px; }
  .block-testimonial:nth-child(odd) .review-block .review-content {
    background: var(--color-gray); }
    .block-testimonial:nth-child(odd) .review-block .review-content::after {
      background: var(--color-gray); }
    .block-testimonial:nth-child(odd) .review-block .review-content h3 {
      color: var(--color-text-gray); }
    .block-testimonial:nth-child(odd) .review-block .review-content p {
      color: var(--color-text-gray); }
  .review-block .review-content {
    position: relative;
    margin-bottom: 30px;
    padding: 35px 40px;
    background: #fff;
    box-shadow: 0px 5px 20px rgba(52, 51, 55, 0.1); }
    .review-block .review-content::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 26px;
      width: 40px;
      height: 40px;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background: #fff; }
    .review-block .review-content h3 {
      font-size: 24px; }
    .review-block .review-content p {
      margin-bottom: 20px; }
  .review-block .review-author {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .review-block .review-author__info h4 {
    margin-bottom: 10px; }
  .review-block .review-author__info p {
    margin-bottom: 0; }
  .review-block .avatar {
    position: relative;
    width: 87px;
    height: 87px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden; }
    .review-block .avatar img {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

/*===> End review-block <===*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: var(--color-dark);
  transition: all .5s; }
  .preloader.load {
    opacity: 0;
    visibility: hidden; }

.loader-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #181818;
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s; }

.loader-wrapper.disable {
  opacity: 0;
  visibility: hidden; }

.loader-container {
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  z-index: 200;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none; }

.loader-container > div {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 2px;
  margin: 0 1px;
  animation: load 3s ease-in-out infinite; }

.loader-container .rectangle-2 {
  animation-delay: 0.1s; }

.loader-container .rectangle-3 {
  animation-delay: 0.2s; }

.loader-container .rectangle-4 {
  animation-delay: 0.3s; }

.loader-container .rectangle-5 {
  animation-delay: 0.4s; }

.loader-container .rectangle-6 {
  animation-delay: 0.5s; }

@keyframes load {
  0%,
  100% {
    transform: scaleY(1);
    background: #7B16D9; }
  16.67% {
    transform: scaleY(3);
    background: #d916c1; }
  33.33% {
    transform: scaleY(1);
    background: #378ed6; }
  50% {
    transform: scaleY(3);
    background: #d54c45; }
  66.67% {
    transform: scaleY(1);
    background: #d54d44; }
  83.34% {
    transform: scaleY(3);
    background: #FF6600; } }

/*===> Begin to-top <===*/
.to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: 60px;
  z-index: 100;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 50%;
  background: var(--color-primary);
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  cursor: pointer; }
  .to-top.show-up {
    opacity: 1;
    visibility: visible; }
  .to-top:hover {
    background: var(--color-secondary); }
  @media (max-width: 991px) {
    .to-top {
      bottom: 60px;
      right: 20px;
      width: 40px;
      height: 40px; } }

.wagtail-userbar--bottom-right { bottom: 8em!important; }

/*===> End to-top <===*/
